import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

export default function SelectAutocomplete(props) {
    const { options, value, ...restProps } = props;

    return (
        <Autocomplete
            options={options || []}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.label}
                        {...getTagProps({ index })}
                        size="small"
                    />
                ))
            }
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={(_, newValue) => props.onChange(restProps.name, newValue)}
            renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" fullWidth />
            )}
        />
    );
}