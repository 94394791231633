import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { IconButton, Typography } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DataTabs from './DataTabs';
import FileUpload from '@mui/icons-material/FileUpload';
import Close from '@mui/icons-material/Close';

const InvoiceAttachments = ({ invoiceId, attachments, appendAttachments, onDelete }) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                endIcon={<FileUpload />}
                sx={{
                    // No border radius
                    borderRadius: 0,
                }}
                fullWidth
                onClick={handleClick}
            >
                Upload Files
            </Button>
            <Dialog
        open={open}
        onClose={handleClose}
        size="xlg"
        sx={{
            '& .MuiDialog-paper': {
                width: '50%',
                minHeight: '60vh',
                maxWidth: 'none',
                maxHeight: '90vh',
                borderRadius: 'none'
            }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{
            borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between', alignItems: 'center'
        }}>
            <Typography variant='h6'>
          Files selection
            </Typography>
            <IconButton onClick={handleClose} color="primary" autoFocus>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p:0 }}>
          <DataTabs appendAttachments={appendAttachments} attachments={attachments} invoiceId={invoiceId} onDelete={onDelete} />
        </DialogContent>
        
      </Dialog>
        </div>
    );
};

export default InvoiceAttachments;