import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Creating the async thunk for fetching patients
export const fetchGeneralPatients = createAsyncThunk('patients/fetchGeneralPatients', async () => {
    try {
        const response = await instance.get(`/patients`);  // Changed API endpoint to '/patients'
        return response.data; // API response is an array of patients
    } catch (error) {
        throw error;
    }
});


// Creating the slice for patients
export const GeneralPatients = createSlice({
    name: 'patients',
    initialState: {
        allData: [],
        data: [],
        loading: true,
        error: null
    },
    reducers: {
        setGPatientsData: (state, action) => {
            state[action.payload.key] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeneralPatients.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGeneralPatients.fulfilled, (state, action) => {
                state.loading = false;
                state.allData = action.payload;
                state.data = action.payload;
                state.mainData = action.payload;
            })
            .addCase(fetchGeneralPatients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setGPatientsData } = GeneralPatients.actions;

export default GeneralPatients.reducer;

