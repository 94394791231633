import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Close from '@mui/icons-material/Close';

import instance from '../../../../auth/utils/useJwt';
import { useState } from 'react';
import { Button, Checkbox, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function DeleteAttachment({
    attachmentId, onDelete
}){
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [deleteGDrive, setDeleteGDrive] = useState(false)
    const {invoice_id: invoiceId} = useParams()
    const [deleting, setDeleting] = useState(false)
    const handleClick = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteAttachment = () => {
        setDeleting(true)
        setError(null)
        instance.delete(`/xero/invoices/${invoiceId}/attachments`, {
            params: {
                attachment_id: attachmentId,
                delete_gdrive: deleteGDrive
            },
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            setDeleteGDrive(false)
            onDelete(attachmentId)
            setDeleting(false)
            handleClose()
        }).catch(err => {
            console.log(err)
            setError("An error occurred while deleting attachment. Please try again later.")
            setDeleting(false)
        })
    }
    


    return (
        <div>
            <IconButton size='small'
                onClick={handleClick}
                color="error"
            >
                <Close />
            </IconButton>
            
            <Dialog
                open={open}
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '40%',
                    }
                }}
            >
                <DialogTitle sx={{ borderBottom: "1px solid #ccc" }}>Delete Attachment</DialogTitle>
                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText>
                        Are you sure you want to delete this attachment?<br />
                        The file will be removed from eHR and Xero, and will be unlinked in Drive
                    </DialogContentText>
                    <DialogContentText sx={{fontSize: 13, color: 'text.secondary'}}>
                        <Checkbox 
                        checked={deleteGDrive}
                        onChange={e => setDeleteGDrive(e.target.checked)}
                        />  Delete the file from the case's expense folder in Google Drive
                    </DialogContentText>
                    {
                        error && <Typography 
                        sx={{marginTop: 2, fontSize: 13}}
                        color="error">{error}</Typography>
                    }
                </DialogContent>
                <DialogActions sx={{ px: 2, py: 2, borderTop: '1px solid #ccc' }}>
                    <Button onClick={handleClose}
                    variant='outlined'
                    disabled={deleting}
                    >Cancel</Button>
                    <Button onClick={deleteAttachment}
                    disabled={deleting}
                    variant='contained'
                     color="error">
                        { deleting ? "Deleting..." : "Delete" }
                     </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}