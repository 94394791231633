import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleLogin } from '../../../redux/authentication';
import { setAccessToken, setRefreshToken } from '../../../auth/utils';

export default function AuthLoggedIn() {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const dispatch = useDispatch()

    useEffect(() => {
        
        setAccessToken(query.get("accessToken"))
        setRefreshToken(query.get("refreshToken"))
        dispatch(handleLogin({
            name: query.get("name"),
            picture: query.get("picture"),
            accessToken: query.get("accessToken"),
            refreshToken: query.get("refreshToken")
        }))
        //navigate("/")
        const defaultPage = localStorage.getItem('romacDefaultPage', "/") || "/";
        if (localStorage.getItem("nextRomac")){
            var next = localStorage.getItem("nextRomac")
            // remove from local storage
            localStorage.removeItem("nextRomac")

            if (["/login", "/signup", "/auth/loggedin"].includes(next)){
                next = "/"
            }
            if (next === "/"){
                console.log("moving to next")
                window.location.href = defaultPage
            }else{
                window.location.href = next
            } 
        }
        else{
            window.location.href=defaultPage
        }
    }, [])


    return (
        <div>
            
        </div>
    )
}