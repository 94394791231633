import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteDialog from '../../../../../components/Dialogs/DeleteDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useSnackbar from '../../../../../components/Dialogs/SnackBar';
import instance from '../../../../../auth/utils/useJwt';


export default function DeleteMemo(props) {
    const { item, fetchMemos } = props
    const [deleteAc, setDeleteAc] = useState({
        id: null, loading: false, open: false
    })
    const [SnackbarComponent, showSnackbar] = useSnackbar();

    const onDeleteAction = (ac) => {
        // On Delete action
        if (!ac) {
            setDeleteAc({ id: null, open: false, loading: false })
            return
        }
        setDeleteAc({ ...deleteAc, loading: true })

        instance.delete(`/case-memos?id=${deleteAc.id}`).then(res => {
            console.log(res)
            setDeleteAc({ ...deleteAc, loading: false, id: null, open: false })
            showSnackbar("Record deleted successfully", "success")
            fetchMemos()
        }).catch(err => {
            console.log(err)
            setDeleteAc({ ...deleteAc, loading: false, id: null, open: false })
        })
    }

    return (
        <>
        <SnackbarComponent />
        
            <IconButton size='small' onClick={() => setDeleteAc({
                id: item.id, open: true, loading: false
            })}>
                                        <DeleteForeverIcon color='error' sx={{ fontSize: '20px' }} />
                                    </IconButton>

            <DeleteDialog open={deleteAc.open} deleting={deleteAc.loading} onAction={onDeleteAction} />
        </>
    );
}
