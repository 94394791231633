

export function isUserLoggedIn() {
    return getAccessToken() !== null
}

export const setAccessToken = (token) => {
    document.cookie = `romacAccessToken=${token}; expires=Thu, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const getAccessToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('romacAccessToken=')) {
            return cookie.substring('romacAccessToken='.length, cookie.length);
        }
    }
    return null;
};

export const deleteAccessToken = () => {
    document.cookie = 'romacAccessToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

export const setRefreshToken = (token) => {
    document.cookie = `romacRefreshToken=${token}; expires=Thu, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const getRefreshToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('romacRefreshToken=')) {
            return cookie.substring('romacRefreshToken='.length, cookie.length);
        }
    }
    return null;
};

export const deleteRefreshToken = () => {
    document.cookie = 'romacRefreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};
