import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const rows = Array.from({ length: 8 });

export default function LoadingOverlay() {
  return (
    <Box sx={{ width: "97%", px: 2 }}>
      {rows.map((_, index) => (
        <Skeleton sx={{
            marginBottom: 0
        }} key={index} height={50} animation="wave" />
      ))}
    </Box>
  );
}
