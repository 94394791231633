import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layout/AppLayout";
import { Card, CardContent, Box, Button, CircularProgress, Typography } from "@mui/material";
import instance from "../../auth/utils/useJwt";
import { DATE_FORMAT } from "../../configs/basic";
import dayjs from 'dayjs';
import BContainer from "../../components/BContainer";
import BreadCrumbs from "../../components/BreadCrumbs";
import DataGridTable from "./DataTable";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MuiDataGrid from "../../components/tables/MUIDataGrid";

export default function DonationsPage() {
    const [loading, setLoading] = useState(true)
    const [allDonations, setAllDonations] = useState([])

    const fetchDonations = () => {
        instance.get("/donations").then(res => {
            // console.log(res)
            var dt = []
            // Set index+1 as id
            res.data.forEach((item, index) => {
                item.row_id = item.id
                item.id = index + 1
                dt.push(item)
            })
            setAllDonations(dt)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchDonations()
    }, [])

    const breadcrumbs_v = [
        { text: "Donations", url: "/donations" }
    ]

    const user = useSelector(state => state.user)

    const get_name = (row) => {
        console.log(row)
        if (row.first_name) {
            return `${row.first_name} ${row.last_name}`
        }else if (row.organization) {
            return row.organization
        }else if (row.club) {
            return row.club
        }
    }

    const COLUMNS = [
        { field: 'donor_type', headerName: 'Donor Type', flex: 1, renderCell: (params) => {
            return <Typography variant="body2" style={{ textTransform: 'capitalize' }}>{params.row.donor_type?.replace("_", " ")}</Typography>
        } },
        { field: 'donation_from', headerName: 'From', width: 200, renderCell: (params) => {
           return <Link to={`/donations/${params.row.row_id}`}>{get_name(params.row)}</Link>
        } },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 200 , renderCell: (params) => {
            return params.row.phone ? params.row.phone : 'N/A'
        }},
        
        { field: 'amount', headerName: 'Amount', width: 150, renderCell: (params) => {
            return `$${params.row.amount?.toFixed(2) || "0.00"}`;
        } },
        {
            field: 'donation_type', headerName: 'Donation Type', flex: 1, renderCell: (params) => {
                return <Typography variant="body2" style={{ textTransform: 'capitalize' }}>{params.row.donate_by?.replace("_", " ")}</Typography>
            }
        },
        {
            filed: "recurrence", headerName: "Recurrence", flex: 1, renderCell: (params) => {
                return params.row.donation_type
            }
        },
        {
            field: "country", headerName: "Country", flex: 1
        },
        {
            field: 'created_at', headerName: 'Date', width: 150, renderCell: (params) => {
                return params.row.created_at ? dayjs(params.row.created_at).format(DATE_FORMAT) : ""
            }
        }
    ]

    return (
        <AppLayout title="Donations" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <BreadCrumbs data={breadcrumbs_v} />
                </Box>
            </BContainer>
            
                { user.donations ?
                <MuiDataGrid data={allDonations} loading={loading} columns={COLUMNS} />
            :
            <Box sx={{ px: 3 }}>
            <Card sx={{ margin: '0 auto', mt: 3 }}>
                <CardContent sx={{ px: 0, py: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                        You have no permission to view this page
                    </Box>
                </CardContent>
            </Card>
            </Box>
                }
        </AppLayout>
    )
}