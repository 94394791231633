import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, Divider, Grid, styled } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

import icReportAnalytics from '../../../assets/images/icons/icReportAnalytics.png'
import { Link, useNavigate } from 'react-router-dom';
import AppLayout from '../../../components/layout/AppLayout';
import BContainer from '../../../components/BContainer';
import BreadCrumbs from '../../../components/BreadCrumbs';

const ViewButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#E9F6FF", color: "#444", textTransform: 'capitalize'
}))

const SingleReport = ({ name, icon, url, details, report_type }) => {
    const navigate = useNavigate()
    return (
        <Box sx={{
            border: '1px solid #eee', textAlign: 'center', py: 2, cursor: 'pointer', borderRadius: '5px',
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#E9F6FF",
            }
        }} onClick={() => navigate(url)}>

            <Box sx={{ display: 'flex' }}>
                <Link to={url} style={{ color: "#3B82F6", textDecoration: 'none', paddingLeft: '10px', paddingRight: '10px' }}>
                    {icon}
                </Link>
                <Box sx={{ color: "#000" }}>
                    <Typography sx={{ fontWeight: 'bold', textDecoration: 'none', mt: 2, fontSize: '14px', textAlign: 'left' }}>{name}</Typography>
                    <Typography sx={{ textDecoration: 'none', fontSize: '13px' }}>Report Type: {report_type}</Typography>
                </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography sx={{ fontSize: '13px', textAlign: 'left', px: 2 }}>{details}</Typography>

        </Box >
    )
}

const ReportsOverview = () => {
    const IconSize = "70px"
    const items = [
        {
            name: "Patient Cases", icon: <AnalyticsOutlinedIcon sx={{ fontSize: IconSize }} />, url: "/reports/patient-cases",
            report_type: "Interactive Table", details: "Query the patient data to build custom tables of patient case data."
        },
        {
            name: "Expense", icon: <MonetizationOnOutlinedIcon sx={{ fontSize: IconSize }} />, url: "/reports/expenditures",
            report_type: "Interactive Table", details: "Query the case expenditure data to build custom tables of costs and estimates."
        },
        {
            name: "Performance (Annual)", icon: <AnalyticsOutlinedIcon sx={{ fontSize: IconSize }} />, url: "/reports/annual",
            report_type: "Detailed Statement", details: "A detailed report of ROMAC's key performance indicators for a custom time period."
        },
        {
            name: "ROMAC Map", icon: <MapOutlinedIcon sx={{ fontSize: IconSize }} />, url: "/reports/map",
            report_type: "Geographic Data", details: "Display and filter patients by geographic region."
        },
    ]
    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Reports" }
                ]} />
            </BContainer>


            <Grid container spacing={3} sx={{ px: 4, py: 2 }}>
                {items.map((item, index) => (
                    <Grid item md={3} key={`report-${index}`}>
                        <SingleReport icon={item.icon} name={item.name} url={item.url} details={item.details} report_type={item.report_type} />
                    </Grid>
                ))}
            </Grid>
        </AppLayout>
    );
}

export default ReportsOverview;
