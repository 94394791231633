import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Create an async thunk to fetch countries
export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
  try {
    const response = await instance.get('/countries');
    return response.data; // Assuming the API response is an array of countries
  } catch (error) {
    throw error;
  }
});

export const Country = createSlice({
  name: 'countries',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    addCountry: (state, action) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addCountry } = Country.actions;

export default Country.reducer;
