import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Creating the async thunk for fetching referral patients
export const fetchReferralPatients = createAsyncThunk('referralPatients/fetchReferralPatients', async (archived) => {
    try {
        const response = await instance.get(`/patients?archived=${archived}&referral=0`); // Added 'referral=0' to the API endpoint
        return response.data; 
    } catch (error) {
        throw error;
    }
});

// Creating the slice for referral patients
export const ReferralPatients = createSlice({
    name: 'referralPatients',
    initialState: {
        allData: [],
        data: [],
        archived: "no",
        editRow: null,
        loading: false,
        error: null,
        perPage: 10,
        currentPage: 1,
        totalPages: 0,
        searchInput: "",
        sortConfig: {
            key: null,
            direction: 'asc',
        },
        filters: [

        ],
        
        selectedFilters: null
    },
    reducers: {
        addReferralPatient: (state, action) => {
            state.data.push(action.payload);
        },
        setReferralPatientsData: (state, action) => {
            state[action.payload.key] = action.payload.value
            if (action.payload.key === 'perPage') {
                state.totalPages = Math.ceil(state.allData.length / state.perPage)
            }
            else if (action.payload.key === "searchInput") {
                const searchQuery = action.payload.value.toLowerCase();
                state.data = state.allData.filter(patient => {
                    return (
                        patient.romac_id?.toLowerCase().includes(searchQuery) ||
                        patient.first_name?.toLowerCase().includes(searchQuery) ||
                        patient.last_name?.toLowerCase().includes(searchQuery) ||
                        patient.dob?.toLowerCase().includes(searchQuery) ||
                        patient.medical_classification?.toLowerCase().includes(searchQuery) ||
                        patient.region?.toLowerCase().includes(searchQuery) ||
                        patient.status?.toLowerCase().includes(searchQuery) ||
                        patient.country?.toLowerCase().includes(searchQuery)
                    );
                });

                state.totalPages = Math.ceil(state.data.length / state.perPage);
                state.currentPage = 1; // Reset to first page after search
                const startIndex = (state.currentPage - 1) * state.perPage;
                const endIndex = startIndex + state.perPage;
                state.data = state.data.slice(startIndex, endIndex);
            }
        },
        changeReferralPage: (state, action) => {
            state.currentPage = action.payload;
            const startIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = startIndex + state.perPage;
            state.data = state.allData.slice(startIndex, endIndex);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReferralPatients.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchReferralPatients.fulfilled, (state, action) => {
                state.loading = false;
                state.allData = action.payload;
                state.currentPage = 1;
                if (action.payload.length > state.perPage) {
                    state.data = action.payload.slice(0, state.perPage);
                    state.totalPages = Math.ceil(action.payload.length / state.perPage);
                } else {
                    state.data = action.payload;
                    state.totalPages = 1;
                }
            })
            .addCase(fetchReferralPatients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { addReferralPatient, changeReferralPage, setReferralPatientsData } = ReferralPatients.actions;

export default ReferralPatients.reducer;
