import { Box, Skeleton, Stack } from "@mui/material";

export default function LoadSkeleton({
    rows = 5,
}) {
    
    return (
        <Box>
            <Stack spacing={1} direction="column">
                {[...Array(rows)].map((_, index) => (
                    <Skeleton key={index} variant="text" width="100%" />
                ))}
            </Stack>
        </Box>
    )
}