import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Create an async thunk to fetch districts
export const fetchUsers = createAsyncThunk('districts/fetchUsers', async () => {
    console.log("will fetch users")
    try {
        const response = await instance.get('/admin/users');
        return response.data; // API response is an array of users
    } catch (error) {
        console.log(error)
        throw error;
    }
});

export const Users = createSlice({
    name: 'users',
    initialState: {
        allData: [],
        data: [],
        editRow: null,
        loading: false,
        error: null,
        perPage: 10,
        currentPage: 1,
        totalPages: 0,
    },
    reducers: {
        addUser: (state, action) => {
            state.data.push(action.payload);
        },
        setUsersData: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        changePage: (state, action) => {
            state.currentPage = action.payload;
            const startIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = startIndex + state.perPage;
            state.data = state.allData.slice(startIndex, endIndex);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.allData = action.payload;
                state.data = action.payload
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { addUser, changePage, setUsersData } = Users.actions;

export default Users.reducer;
