import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route, Routes,
  useNavigate
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Dashboard from "../pages/Dashboard";
import Referrals from "../pages/Referrals";
import Patients from "../pages/Patients/List";
import PatientDetails from "../pages/Patients/Details";
import PatientEdit from "../pages/Patients/Edit";
import Login from "../pages/authentication/login";
import PatientCaseDetails from "../pages/Patients/CaseDetails";
import AdminLists from "../pages/admin/lists";
import AdminUsers from "../pages/admin/users";
import AdminRoles from "../pages/admin/roles";
import ReportsPatientCases from "../pages/Reports/PatientCases";
import ReportsExpenditures from "../pages/Reports/Expenditures";
import UserProfile from "../pages/User/Profile";
import PatientAdd from "../pages/Patients/Add";

import AuthLoggedIn from "../pages/authentication/loggedin";
import AdminRoute from "./AdminRoute";
import Training from "../pages/Training";
import MapPage from "../pages/map";
import PatientsHandle from "../pages/handlers/PatientsHandle";
import AllActivities from "../pages/Activities";
import AnnualReports from "../pages/Reports/AnnualReports";
import Documentation from "../pages/Documentation";
import Support from "../pages/Support";
import AdminAppSettings from "../pages/admin/settings";
import ReportsOverview from "../pages/Reports/Overview";
import InsuranceReports from "../pages/Reports/Insurance";
import AllCases from "../pages/Cases";
import PAMInvoices from "../pages/pam/Invoices";
import PatientCaseInvoiceDetails from "../pages/Patients/InvoiceDetails";
import DonationsPage from "../pages/Donations";
import DonationsDetailsPage from "../pages/Donations/DonationDetails";
import ReportsRoute from "./ReportsRoute";
import NotificationsPage from "../pages/Notifications";
import AdminUserDetails from "../pages/admin/users/UserDetails";
import InsuranceGroupedReports from "../pages/Reports/Insurance/InsuranceGrouped";


// function HashRedirect() {
//   console.log('hash redirect')
//   React.useEffect(() => {
//     console.log(window.location)
//     const hash = window.location.hash.substring(1); // Remove the '#' symbol
//     if (hash) {
//       // Assuming the hash is like 'patient/r/122'
//       const parts = hash.split('/'); // ['patient', 'r', '122']
//       if (parts.length === 3 && parts[0] === 'patient' && parts[1] === 'r') {
//         const newId = `R-${parts[2].padStart(4, '0')}`; // 'R-0122'
//         const newPath = `/patient/${newId}`;
//         window.location.href = newPath;
//       }
//     }
//   }, []);

//   // Render nothing or a loading spinner
//   return null;
// }

function HashHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash.includes('/patient/')) {
      // Extract the relevant parts of the hash and navigate
      const newPath = hash.replace('#', ''); // Remove the '#' symbol
      const parts = newPath.split('/');
      if (parts.length === 4 && parts[1] === 'patient') {
        const newId = `${parts[2].toUpperCase()}-${parts[3].padStart(4, '0')}`; // 'R-0122'
        navigate(`/patients/${newId}`)
      }else if(parts.length === 6 && parts[1] === 'patient'){
        const newId = `${parts[2].toUpperCase()}-${parts[3].padStart(4, '0')}/case/${parts[5]}`;
        navigate(`/patients/${newId}`)
      }
      //navigate(newPath);
    }
  }, [navigate]);

  // Render nothing or a loading indicator
  return null;
}

function RouterComp() {
  //console.log(user)
  return (
    <Router>
      <HashHandler />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth/loggedin" element={<AuthLoggedIn />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/cases" element={<AllCases />} />
          <Route path="/patients/:patient_id" element={<PatientDetails />} />
          <Route path="/patients/add/:type" element={<PatientAdd />} />
          <Route path="/patients/:patient_id/edit" element={<PatientEdit />} />
          <Route path="/patients/:patient_id/case/:case_id" element={<PatientCaseDetails />} />
          <Route path="/patients/:patient_id/case/:case_id/invoice/:invoice_id" element={<PatientCaseInvoiceDetails />} />
          <Route path="/user/profile" element={<UserProfile />} />
          <Route path="/training" element={<Training />} />
          <Route path="/all-activity" element={<AllActivities />} />
          <Route path="/#/patient/:r/:id" element={<PatientsHandle />} />

          <Route path="/docx" element={<Documentation />} />
          
          <Route path="/docx/:mod" element={<Documentation />} />
          <Route path="/docx/:mod/:submod" element={<Documentation />} />
          <Route path="/docx/:mod/:submod/:subsubmod" element={<Documentation />} />


          <Route path="/support" element={<Support />} />
          <Route path="/pam/expenses" element={<PAMInvoices />} />

          
          <Route path="/donations" element={<DonationsPage />} />
          <Route path="/donations/:id" element={<DonationsDetailsPage />} />
          
          <Route path="/notifications" element={<NotificationsPage />} />

        </Route>
        <Route element={<ReportsRoute />}>
          
        <Route path="/reports/overview" element={<ReportsOverview />} />
          <Route path="/reports/map" element={<MapPage />} />
          <Route path="/reports/patient-cases" element={<ReportsPatientCases />} />
          <Route path="/reports/expenditures" element={<ReportsExpenditures />} />
          
          <Route path="/reports/insurance" element={<InsuranceGroupedReports />} />
          <Route path="/reports/insurance/details" element={<InsuranceReports />} />
          <Route path="/reports/annual" element={<AnnualReports />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/lists" element={<AdminLists />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/users/:uuid" element={<AdminUserDetails />} />
          <Route path="/admin/roles" element={<AdminRoles />} />
          <Route path="/admin/settings" element={<AdminAppSettings />} />
        </Route>
      </Routes>
    </Router>
  )
}


export default RouterComp