import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputLabel from '../../../components/forms/CustomInputLabel';
import { useForm } from 'react-hook-form';
import { setAllCasesDataFiltered } from '../../../redux/all_cases';
import MUIAutocompleteMultipleCheck from '../../../components/forms/MUIAutocompleteMultipleCheck';

export default function RegionFilter(props) {
    const { filters } = useSelector(state => state.all_cases)
    const dispatch = useDispatch()
    const { control, watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            region: [
                { label: "All", value: "all" }
            ]
        }
    })

    const region_val = watch("region")
    useEffect(() => {
        dispatch(setAllCasesDataFiltered({
            region: region_val
        }))
    }, [region_val])

    const item = filters[0]
    if (!item) {
        return <Box></Box>
    }

    return (
        <Box sx={{
            maxWidth: '300px',
            minWidth: '150px',
            ...props.sx
        }}>
            <CustomInputLabel sx={{
                fontWeight: 700
            }}>{item.label}</CustomInputLabel>
            <MUIAutocompleteMultipleCheck name={item.id} errors={errors} control={control} options={item.options} chips={false} />

        </Box>



    );
}
