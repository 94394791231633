import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useEffect, useState } from "react";

import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from "react-redux";
import { setCaseValue } from "../../../../redux/case/case";
import instance from "../../../../auth/utils/useJwt";
import CustomInputLabel from "../../../../components/forms/CustomInputLabel";
import DatePicker from "../forms/DatePicker";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem"
import { Link } from "react-router-dom";

const ViewPreview = ({ data }) => {
    if (!data) {
        return <Box></Box>
    }
    // If data, open line in new tab
    return (
        <CustomInputLabel sx={{ color: '#09f' }}>
            <Link style={{ textDecoration: 'none' }} to={data.url} target="_blank">View</Link>
        </CustomInputLabel>
    )
    }
export default function Logistics() {
    const { data, loading } = useSelector(state => state.patient_case)

    const dispatch = useDispatch()
    
    const onChange = (e) => {
        const { name, value } = e.target
        dispatch(setCaseValue({
            name: name, value: value
        }))

    }

    const [files, setFiles] = useState([])

    useEffect(() => {
        instance.get(`/case/files?drive_id=${data?.drive_id}&all=true&type=logistics`).then(res => {
            var out = []
            res.data.map(item => (
                out.push({
                    value: item.id, label: item.name, url: item.webViewLink
                })
            ))
            setFiles(out)
        }).catch(err => {
        })
    }, [data?.drive_id])

    if (!data) {
        return <Box></Box>
    }
    
    console.log(data.immigration_file_2)

    return (
        <Box>
            <Box sx={{ mb: 1 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={4}>
                        <CustomInputLabel>Passport</CustomInputLabel>
                        <TextField size="small" value={data?.passport} onChange={onChange} name="passport" fullWidth />
                    </Grid>
                    <Grid item md={4}>
                        <CustomInputLabel>Visa Expire</CustomInputLabel>
                        <DatePicker name="visa_expire" value={data.visa_expire} />
                        
                    </Grid>
                    <Grid item md={4}>
                        <CustomInputLabel>Accompanying Guardian</CustomInputLabel>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data?.guardian_id}
                                onChange={(e) => dispatch(setCaseValue({
                                    name: "guardian_id", value: e.target.value
                                }))}
                            >
                                <MenuItem value={null}>No Guardian</MenuItem>
                                {
                                    data.guardians?.map((item, index) => (
                                        <MenuItem key={`guardian-${index}`} value={item.value}>{item.label}</MenuItem>
                                    ))

                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CustomInputLabel>Documentation</CustomInputLabel>
                            <ViewPreview data={data.immigration_file_1} />
                        </Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={files}
                            fullWidth
                            value={data.immigration_file_1}
                            onChange={(e, val) => dispatch(setCaseValue({
                                name: "immigration_file_1", value: val
                            }))}
                            size="small"
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item md={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CustomInputLabel>Documentation</CustomInputLabel>
                            <ViewPreview data={data.immigration_file_2} />
                        </Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={files}
                            fullWidth
                            value={data.immigration_file_2}
                            onChange={(e, val) => dispatch(setCaseValue({
                                name: "immigration_file_2", value: val
                            }))}
                            size="small"
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item md={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CustomInputLabel>Documentation</CustomInputLabel>
                            <ViewPreview data={data.immigration_file_3} />
                        </Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={files}
                            fullWidth
                            value={data.immigration_file_3}
                            onChange={(e, val) => dispatch(setCaseValue({
                                name: "immigration_file_3", value: val
                            }))}
                            size="small"
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                    </Grid>

                </Grid>
            </Box>

            <Box sx={{ my: 2 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>Dates</Typography>
                <Divider />
            </Box>
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2} columns={10}>
                    <Grid item md={2}>
                        <CustomInputLabel>Arrival Date</CustomInputLabel>
                        <DatePicker name="arrival_date" value={data.arrival_date} />
                        
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Pre-Operation Date</CustomInputLabel>
                        <DatePicker name="pre_operation_date" value={data.pre_operation_date} />
                      
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Operation Date</CustomInputLabel>
                        <DatePicker name="operation_date" value={data.operation_date} />
                        
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Departure Date</CustomInputLabel>
                        <DatePicker name="departure_date" value={data.departure_date} />
                       
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ my: 2 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>Hosting Family</Typography>
                <Divider />
            </Box>
            <Box sx={{ my: 2 }}>

                <Grid container spacing={2} columns={10}>
                    <Grid item md={2}>
                        <CustomInputLabel>Family Name</CustomInputLabel>
                        <TextField size="small" value={data.hosting_family_name} onChange={onChange} name="hosting_family_name" />
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Street Address</CustomInputLabel>
                        <TextField size="small" value={data.hosting_street_address} onChange={onChange} name="hosting_street_address" />
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Suburb</CustomInputLabel>
                        <TextField size="small" value={data.hosting_suburb} onChange={onChange} name="hosting_suburb" />
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Home Tel</CustomInputLabel>
                        <TextField size="small" value={data.hosting_home_tel} onChange={onChange} name="hosting_home_tel" />
                    </Grid>
                    <Grid item md={2}>
                        <CustomInputLabel>Mobile</CustomInputLabel>
                        <TextField size="small" value={data.hosting_mobile} onChange={onChange} name="hosting_mobile" />
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}