import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function PatientsHandle(props) {
  const navigate = useNavigate();
  const { r, id } = useParams(); // Assuming the route is set up to capture 'r' and 'id'

  useEffect(() => {
    // Assuming you want to redirect immediately when the component is mounted
    // and that 'id' should be zero-padded to 4 digits
    const paddedId = id.padStart(4, '0');
    navigate(`/patients/${r.toUpperCase()}-${paddedId}`);
  }, [r, id, navigate]);

  // Render nothing or a loading indicator as this component is only for redirection
  return null;
}