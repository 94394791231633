import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

// Create an async thunk to fetch regions
export const fetchRegions = createAsyncThunk('regions/fetchRegions', async () => {
  try {
    const response = await instance.get('/regions');
    return response.data; // Assuming the API response is an array of regions
  } catch (error) {
    throw error;
  }
});

export const deleteRegion = createAsyncThunk('regions/deleteRegion', async (id) => {
  try {
    await instance.delete(`/regions?id=${id}`);
    return id;  // Return the id of the region that got deleted
  } catch (error) {
    throw error;
  }
});

export const Region = createSlice({
  name: 'regions',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    addRegion: (state, action) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRegion.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(region => region.id !== action.payload);  // Remove the deleted region from the list
      })
      .addCase(deleteRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addRegion } = Region.actions;

export default Region.reducer;
