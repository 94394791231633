// ** React Imports
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getRefreshToken, isUserLoggedIn } from "../auth/utils";

const PrivateRoute = () => {
  const userLoggedIn = isUserLoggedIn();
  const location = useLocation();
  const isRefreshToken = getRefreshToken() ? true : false;

  // Encode the current route path to include in the 'next' query parameter
  const nextParam = encodeURIComponent(location.pathname);
  const sessionExp = isRefreshToken ? '&sessionExpired=true' : '';
  return userLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?next=${nextParam}${sessionExp}`} />
  );
};

export default PrivateRoute;