import { Button, Card } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import ListTabs from "./Tabs";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";

export default function AdminLists() {
    const [open, setOpen] = useState(false);
    const [btnLabel, setBtnLabel] = useState('Region');

    return (
        <AppLayout title="Admin - Lists" px={0} apppadding={"0px"}>
        <BContainer>
            <BreadCrumbs data={[
                { url: "#", text: "Admin" },
                { text: "Lists" }
            ]} />
            
            <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }} 
            startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                Add {btnLabel}
            </Button>
        </BContainer>
        <Box sx={{ px: 3, py: 1 }}>
            <Card>
                
                <Box>
                    <ListTabs open={open} setOpen={setOpen} onChangeTab={(label) => setBtnLabel(label)} />
                </Box>

            </Card>
        </Box>

        </AppLayout>
    )
}