import { Button, IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";

import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicians } from "../../../../../redux/medical/clinicians";
import { fetchClinicianTypes } from "../../../../../redux/medical/clinician_types";
import { setCaseValue } from "../../../../../redux/case/case";
import AddClinican from "./AddClinician";
import AddClinicanType from "./AddClinicianType";
import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";

export default function Clinicians() {

    const dispatch = useDispatch()

    const patient_case = useSelector(state => state.patient_case)
    const case_data = patient_case.data

    const clinicians = useSelector(state => state.clinicians).data
    const clinician_types = useSelector(state => state.clinician_types).data

    const [acceptanceData, setAcceptanceData] = useState({
        clinicians: []
    })

    useEffect(() => {
        if (clinicians.length === 0) {
            dispatch(fetchClinicians())
        }
        if (clinician_types.length === 0) {
            dispatch(fetchClinicianTypes())
        }

        if (case_data.clinicians?.length === 0) {
            dispatch(setCaseValue({
                name: 'clinicians', value: [
                    { clinician: '', clinician_type: '' }
                ]
            }))
        }

    }, [])

    const onAddClinicians = () => {
        dispatch(setCaseValue({
            name: 'clinicians', value: [
                ...case_data.clinicians, { clinician: '', clinician_type: '' }
            ]
        }))
    }

    const handleChange = (index, newValue, name) => {

        const cln = case_data.clinicians.map((item, idx) => {
            if (idx !== index) { return item; }// Return original object if it's not the one we want to update
            return { ...item, [name]: newValue }// Only create a new object for the one we're updating
        });

        dispatch(setCaseValue({
            name: 'clinicians', value: cln
        }))

    }

    const onDelete = (index) => {
        dispatch(setCaseValue({
            name: 'clinicians', value: case_data.clinicians.filter((item, idx) => idx !== index)
        }))
    }


    return (

        <Box>
            <CustomInputLabel sx={{ mt: 2, fontSize: 18 }}>Clinicians</CustomInputLabel>
            {case_data.clinicians?.map((item, index) => (

                <Grid key={`ck-${index}`} container spacing={2} sx={{ mt: 1 }} columns={11}>
                    <Grid item md={5}>
                        <Box>
                            <CustomInputLabel sx={{ display: 'flex', gap: 0.5 }}>Clinician Type {' | '} <AddClinicanType /></CustomInputLabel>
                        </Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={clinician_types.map(({ id, name }) => ({ label: name, value: id }))}
                            fullWidth
                            value={item.clinician_type}
                            size="small"
                            onChange={(e, newValue) => handleChange(index, newValue, "clinician_type")}
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <Box>
                            <CustomInputLabel sx={{ display: 'flex', gap: 0.5 }}>Clinician {' | '} <AddClinican /></CustomInputLabel>

                        </Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={clinicians.map(({ id, name }) => ({ label: name, value: id }))}
                            fullWidth
                            value={item.clinician}
                            size="small"
                            onChange={(e, newValue) => handleChange(index, newValue, "clinician")}
                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                        />
                    </Grid>

                    <Grid item md={1}>
                        <IconButton
                            onClick={() => onDelete(index)}
                            aria-label="delete"
                            sx={{ mt: 2.5 }}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                <Button onClick={onAddClinicians} size="small" variant="contained">Add Clinician</Button>
            </Box>

        </Box>
    )
}