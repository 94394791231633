import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { getNestedError } from "../../../../components/forms/utils";

export default function MUIAutocompleteBasic(props) {
    const { required, options, value, index, fontSize, ...restProps } = props;

    const variant = props.variant ? props.variant : 'outlined';

    var borderNone = {};
    if (props.border === false || props.border === "false") {
        borderNone = {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: "none",
                    padding: 0,
                },
            }
        };
    }

    const backgroundColorWhenDisabled = props.disabled ? { backgroundColor: '#eee' } : {};
    const stt = fontSize ? {
        fontSize: fontSize ? '12px' : undefined, padding: 0
    } : {}

    // Updated here to use the whole selected option object
    const selectedOption = options.find(option => option.value === value) || null;

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={options || []}
            disabled={props.disabled}
            // Set the value to the selected option object
            value={selectedOption}
            onChange={(_, newValue) => {
                props.onChange({
                    value: newValue ? newValue.value : null,
                    label: newValue ? newValue.label : null,
                    name: props.name,
                    index: index
                })
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    variant={variant}
                    fullWidth
                    multiline={props.isMultiline}
                    sx={{ ...borderNone, ...backgroundColorWhenDisabled }}
                    inputProps={{
                        ...params.inputProps,
                        style: stt,
                    }}
                />
            )}
        />
    );
}
