import React from 'react';
import { FormControl, Input, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const TableSearchInput = ({
  setSearchInput, searchInput, searchPlaceholder, searchSX
}) => {
    
  return (
    <FormControl variant="standard" sx={{ bgcolor: '#F2F3F4' }}>
      <Input
        id="input-with-icon-adornment"
        placeholder={searchPlaceholder || "Search..."}
        value={searchInput}
        onChange={(e) => {
          setSearchInput(e.target.value)
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        disableUnderline
        sx={{
          bgcolor: '#F2F3F4',
          border: '2px solid #F2F3F4',
          px: 1,
          py: 0.4,
          borderRadius: '5px',
          minWidth: '300px',
          width: '500px',
          ...searchSX,
          '&:before': {  borderRadius: '5px' },
          '&:after': { borderRadius: '5px' },
          '&:hover': {
            border: '2px solid #ccc',
          },
          '&.Mui-focused': {
            border: '2px solid #ccc',
          },
        }}
      />
    </FormControl>
  );
};

export default TableSearchInput;