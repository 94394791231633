import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClinicians } from '../../../../redux/medical/clinicians';
import CustomInputLabel from './components/CustomInputLabel';
import { useForm } from 'react-hook-form';
import instance from '../../../../auth/utils/useJwt';
import DeleteDialog from './components/DeleteDialog';
import DataGridTableLists from './components/DataTableLists';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import FormButtons from './components/FormButtons';
import ErrorMessage from './components/ErrorMessage';


export default function Clinicians({
    open, setOpen
}) {
    const { data, loading } = useSelector(state => state.clinicians)
    const [selectedRow, setSelectedRow] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (data.length === 0) {
            dispatch(fetchClinicians())
        }
    }, [])

    const handleEdit = (row) => {
        setSelectedRow(row)
        setOpen(true)
    }

    const changeOpen = (ot) => {
        setOpen(false)
        setSelectedRow(null)
    }

    const handleDelete = (did) => {
        setDeleteId(did)
    }

    return (
        <Box>
            <DataGridTableLists data={data} loading={loading}
                handleEdit={handleEdit} handleDelete={handleDelete}
                cols={[{ field: 'name', headerName: 'Name', flex: 1 }]}
                additionalComp={
                    <Button variant='contained' sx={{ textTransform: 'capitalize' }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>Add</Button>
                }
            />

            <DeleteDialog heading="Delete Clinician" id={deleteId} onDelete={() => dispatch(fetchClinicians())} api_url={"/clinicians"} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mx: 2 }}>
                <PopUp open={open} setOpen={changeOpen} editRow={selectedRow} />
            </Box>
        </Box>
    );
}


function PopUp(props) {
    const { open, setOpen, editRow } = props
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const dispatch = useDispatch()
    const { register, reset, handleSubmit,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        if (editRow) {
            reset(editRow)
        } else {
            reset({ name: "", id: null })
        }
    }, [editRow])


    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null })
        if (!editRow) {
            instance.post("/clinicians", data).then(res => {
                setApiReq({ loading: false, error: null })
                dispatch(fetchClinicians())
                reset({ name: "", id: null })
                setOpen()
            }).catch(err => {
                setApiReq({ loading: false, error: err.response.data ? err.response.data : "Error adding new region" })
            })
        } else {
            instance.patch("/clinicians", data).then(res => {
                setApiReq({ loading: false, error: null })
                dispatch(fetchClinicians())
                reset({ name: "", id: null })
                setOpen()
            }).catch(err => {
                setApiReq({ loading: false, error: err.response.data ? err.response.data : "Error updating region" })
            })
        }
    }
    const onError = (err) => {
        console.log(err)
    }


    return (
        <GeneralDialog open={open} handleClose={setOpen} title={editRow ? 'Edit Clinician' : 'Add Clinician'}
            onFormSubmit={handleSubmit(onSubmit, onError)} disabled={apiReq.loading}
            actions={<FormButtons loading={apiReq.loading} setOpen={setOpen} />}>


            <Box>
                <CustomInputLabel>Clinician Name</CustomInputLabel>
                <TextField {...register('name', { required: true })} error={Boolean(errors.name)} size='small' fullWidth />

            </Box>
            {<ErrorMessage error={apiReq.error} />}
        </GeneralDialog>
    );
}
