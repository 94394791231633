import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomTypography = styled(Typography)({
    color: '#06f',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    padding: '5px 4px',
    paddingLeft: '8px',
    borderRadius: '4px',
    cursor: 'pointer', // Make text clickable
    "&:hover": {
        backgroundColor: '#f2f2f3',
    }
});

const CustomMenuItem = styled(MenuItem)({
    color: '#06f',
    borderLeft: '3px solid',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#f6f6f7',
    },
});

export default function PerPageSelect({ pageize, onChangePageSize }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageSizeChange = (size) => {
        onChangePageSize(size);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const pageSizes = [10, 20, 50, 100];

    return (
        <div>
            <CustomTypography onClick={handleClick} sx={{
                backgroundColor: open ? '#f6f6f7' : 'white',
            }}>
                {pageize}
                <ArrowDropDownIcon />
            </CustomTypography>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 300, // Adjust height as needed
                            width: 200, // Adjust width as needed
                        },
                    }}
                >
                    {pageSizes.map(size => (
                        <CustomMenuItem key={size} onClick={() => handlePageSizeChange(size)} sx={{
                            borderLeftColor: size === pageize ? '#09f' : '#fff',
                            backgroundColor: size === pageize ? '#f6f6f7' : 'white',
                        }}>
                            {size}
                        </CustomMenuItem>
                    ))}
                </Menu>
            </Popover>
        </div>
    );
}
