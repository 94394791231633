import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography'
import { Grid, Button, styled, Card, Box, CardContent } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import DocumentationIcon from '../../../assets/images/icons/docs 1.png'
import TutorialIcon from '../../../assets/images/icons/Vector (3).png'

const ViewButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#E9F6FF", fontSize: '13px', textTransform: 'capitalize', px: 3, py: 1.5
}))


const MetaCard = ({ title, icon, value, slug }) => {
    const navigate = useNavigate()
    return (
        <Card variant="outlined" sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ pt: 1 }}>
                        {icon}
                    </Box>
                    <Box sx={{ pt: 0.7 }}>
                        <Typography sx={{ fontWeight: 'bold' }} variant={"h5"}>{title}</Typography>
                        <Typography sx={{ mt: 1 }} variant={"p"}>{value}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ViewButton size="small" onClick={() => navigate(slug)}>View More</ViewButton>
                </Box>
            </CardContent>
        </Card>
    )
}

export default function BasicCards() {
    const iconColor = "#666"
    const iconSize = "30px"
    const heightItems = "200px"


    return (
        <Grid container spacing={2} sx={{ py: 3 }}>
            <Grid item md={4} sx={{ height: heightItems }}>
                <MetaCard slug={"/training"} title="Training" value="12 resources" icon={<img src={TutorialIcon} alt="tutorial icon" style={{ height: '30px' }} />} />

            </Grid>
            <Grid item md={4} sx={{ height: heightItems }}>
                <MetaCard slug={"/docx"} title="Documentation" value="1500 resources" icon={<img src={DocumentationIcon} alt="documentation icon" style={{ height: '30px' }} />} />
            </Grid>
            <Grid item md={4} sx={{ height: heightItems }}>
                <MetaCard slug="/support" title="Support" value="Learn more about your options for support" icon={<PhoneIcon sx={{ color: iconColor, fontSize: iconSize }} />} />
                {/* <Card variant="outlined" sx={{ height: '100%', backgroundColor: "#E9F6FF", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            
                            <Box>
                                <Typography variant={"h5"} style={{ color: "#007fff", fontWeight: 800 }}>{"AppDataServices"}</Typography>
                                <Typography sx={{ fontSize: '18px', mt: 0.5 }}>Out <span style={{ color: "#007fff", fontWeight: 700 }}>Impactful</span><br /> Solutions</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Button 
                            startIcon={<PhoneIcon />}
                            sx={{ backgroundColor: "#007fff", color: "#fff", textTransform: 'capitalize', px: 2 }}>
                                Support</Button>
                        </Box>
                    </CardContent>
                </Card> */}
            </Grid>
        </Grid>
    )
}