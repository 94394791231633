import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AppsIcon from '@mui/icons-material/Apps';

import Box from '@mui/material/Box';

import GoogleIcon from '../../../assets/images/icons/google.png'
import GmailIcon from '../../../assets/images/icons/gmail.png'
import CalendarIcon from '../../../assets/images/icons/calendar.png'
import SheetIcon from '../../../assets/images/icons/sheets.png'
import DocsIcon from '../../../assets/images/icons/other.png'


export default function ROMACApps() {
    const [anchorEl, setAnchorEl] = React.useState(null);
   
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigateAndClose = (slug) => {
        setAnchorEl(null);
        window.open(slug, '_blank');
    };

    const data = [
        {
            title: 'Google Drive',
            icon: GoogleIcon,
            url: 'https://drive.google.com/drive'
        },
        // Same format as above for all the other apps
        {
            title: 'Gmail',
            icon: GmailIcon,
            url: 'https://mail.google.com'
        },
        {
            title: 'Google Calendar',
            icon: CalendarIcon,
            url: 'https://calendar.google.com'
        },
        {
            title: 'Google Sheet',
            icon: SheetIcon,
            url: 'https://sheets.google.com'
        },
        {
            title: 'Google Docs',
            icon: DocsIcon,
            url: 'https://docs.google.com'
        },
    ]

    return (
        <>
            <Tooltip title="ROMAC apps">
                <IconButton id="basic-button-romac-apps"
                    aria-controls={open ? 'basic-menu-romac-apps' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                        <AppsIcon sx={{ color: "#555" }} />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu-romac-apps"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button-romac-apps',
                }}
            >
                <Box sx={{ mt: 1 }}>
                {data.map((app) => (
                    <MenuItem sx={{ py: 1.2 }} key={app.title} onClick={() => navigateAndClose(app.url)}>
                        <img src={app.icon} alt="icon" style={{ width: '20px' }} />
                        <Typography sx={{ ml: 1 }} textAlign="center">{app.title}</Typography>
                    </MenuItem>
                ))}
                </Box>
            </Menu>
        </>
    );
}