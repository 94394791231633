import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ZipIcon from '../../../../assets/images/icons/zip.png';
import FolderIcon from '../../../../assets/images/icons/folder.svg';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import instance from '../../../../auth/utils/useJwt';
import { useNavigate } from 'react-router-dom';

function humanReadableSize(size, decimalPlaces=2) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    for(let unit of units) {
        if (size < 1024.0) {
            return size.toFixed(decimalPlaces) + ' ' + unit;
        }
        size /= 1024.0;
    }
    return size.toFixed(decimalPlaces) + ' PB';
}



export default function PatientFiles(props) {
    const patient_data = props.data
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [driveId, setDriveId] = useState(patient_data.drive_id);
    const [files, setFiles] = useState([]);
    const [folderHistory, setFolderHistory] = useState([]); // <-- Folder navigation history
    const [localDataStorage, setLocalDataStorage] = useState({}); // <-- Local storage for data
    
    useEffect(() => {
        if (!driveId) { return }
        setLoading(true);
        setFiles([]);

        if (localDataStorage[driveId]) { // Check if data is present in local storage
            setFiles(localDataStorage[driveId]);
            setLoading(false);
            return;
        }

        instance.get(`/patients/files?id=${patient_data.id}&drive_id=${driveId}`).then(res => {
            console.log(res);
            setFiles(res.data);
            // Storing the data in local storage
            setLocalDataStorage(prevData => ({ ...prevData, [driveId]: res.data }));
            setLoading(false);
        }).catch(err => {
            if (err.response && err.response.status === 401) {
                console.log("Unauth")
                navigate("/login")
            }
            console.log("error");
            setLoading(false);
        });
    }, [driveId]);
    const openDriveInNewTab = () => {
        const driveUrl = `https://drive.google.com/drive/folders/${driveId}`;
        window.open(driveUrl, '_blank');
    }

    const onLabelClick = (dr) => {
        if (dr.mimeType === "application/vnd.google-apps.folder") {
            setFolderHistory(prev => [...prev, driveId]); // Push current folder ID to history
            setDriveId(dr.id);
        }else {
            window.open(dr.webViewLink, "_blank")
        }
    }

    const onBackClick = () => {
        const lastVisited = folderHistory.pop(); // Pop the last folder ID from history
        setDriveId(lastVisited);
        setFolderHistory(folderHistory); // Update the folderHistory state
    }


    return (
        <Box>
            {
                loading &&
                <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }

            {
                !loading && files.length === 0 &&
                <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
                    <Typography color="primary">This folder is empty.</Typography>
                </Box>

            }

            {files.length > 0 &&
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Last Modified</TableCell>
                                <TableCell align="right">File Size</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell onClick={() => onLabelClick(row)}>
                                        <Box sx={{ display: 'flex', cursor: 'pointer', "&:hover" : { color: "#09F" } }}>
                                            <img src={row.mimeType === "application/vnd.google-apps.folder" ? FolderIcon : ZipIcon} style={{ width: '30px' }} />
                                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', ml: 1 }}>{row.name}</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">{row.modifiedTime?.slice(0, 10)}</TableCell>
                                    <TableCell align="right">{row.mimeType === 'application/vnd.google-apps.folder' ? '-' : humanReadableSize(row.size)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label='Actions'>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {
                driveId &&
                <Box sx={{ borderTop: "1px solid #ccc", py: 1, display: 'flex', justifyContent: 'space-between' }}>
                    
                    <Button size="small" variant='contained' disabled={folderHistory.length === 0} onClick={onBackClick}>Back</Button>
                    <Button size="small" variant='contained' color="success" onClick={openDriveInNewTab}>Open Drive</Button>
                </Box>
            }
        </Box>
    );
}
