import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

import instance from '../../../../auth/utils/useJwt';
import DataRows from './DataRows';
import LoadSkeleton from './Skeleton';


export default function Background({
    patient_id
}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const get_background = () => {
        setLoading(true)
        instance.get(`/background?id=${patient_id}`).then(res => {
            setData([
                { title: 'House Size, mxm', value: res.data.house_size },
                { title: 'No. Rooms', value: res.data.number_of_rooms },
                { title: 'No. Occupants', value: res.data.number_of_people },
                { title: "Nearest hospital city", value: res.data.nearest_city },
                { title: "Access to transport", value: res.data.transport },
                { title: "Nearest medical facility, km", value: res.data.nearest_medical_km },
                { title: "Nearest medical facility, hrs", value: res.data.nearest_medical_time },
                { title: "Medical facility served by", value: res.data.medical_practitioner },
                { title: "Age and Gender of Siblings", value: res.data.siblings_details },
                { title: "Access to fresh water supply", value: res.data.drinking_water },
                { title: "Does child attend school", value: res.data.attends_school }
            ]);
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        // On First load fetch data
        get_background()
    }, [])


    return (
        <Box>
            { loading ? <LoadSkeleton rows={10} /> : 
            <DataRows title={"Background"} data={data} />
    }
        </Box>
    )
}