export const getNestedError = (errors, name) => {
    return name.split('.').reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), errors);
  };

  export function formatDate(date) {
    if(date === undefined || date === null){
      return null
    }
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
  }