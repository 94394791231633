import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AppLayout from '../../components/layout/AppLayout';
import instance from '../../auth/utils/useJwt';

function AllActivities(props){
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [activityType, setActivityType] = useState('all')

    const get_activities = () => {
        setLoading(true)
        instance.get(`/activities?type=${activityType}`).then(res => {
            setData(res.data.map((item, index) => ({ ...item, id: index }))) // Add an id property for each item
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    useEffect(() => {
        get_activities()
    }, [activityType])

    // Define columns for DataGrid
    const columns = [
        { field: 'timeago', headerName: 'Time', width: 120 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'status', headerName: 'Status', width: 120 },
        { field: 'action', headerName: 'Action', width: 350 },
        { field: 'comment', headerName: 'Comment', width: 650 },
    ];

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', py: 1.7, px: 3, backgroundColor: "#fff", mb: 2, borderRadius: 'none' }}>
                <Typography variant="h6">All Activity</Typography>
            </Box>

            <Box sx={{ pb: 1, mx: 3 }}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ px: 2, py: 1 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }} variant="h6">Data</Typography>
                        </Box>

                        <Switch checked={activityType === 'all'} onChange={(e) => setActivityType(e.target.checked ? 'all' : 'single')} />
                    </Box>

                    
                        <DataGrid
                            rows={data}
                            loading={loading}
                            columns={columns}
                            density="compact"
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100, 500]}
                            autoHeight
                        />
                   
                </Card>
            </Box>
        </AppLayout>
    )
}

export default AllActivities
