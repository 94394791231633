import { Button, Card, Checkbox, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function Security() {


    return (
        <Box sx={{ pt: 1 }}>
            <Box>
                <Typography>Change Password</Typography>
                <Typography sx={{
                    textAlign: 'center',
                    fontSize: '13px',
                    py: 1.4,
                    my: 1.5,
                    backgroundColor: '#F1F9FE',
                    color: '#54B0F2'
                }}>
                   You can manage your password from the <b>Google My Account Page</b>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button variant="contained" onClick={() => window.open('https://myaccount.google.com/signinoptions/password', '_blank')}>Google Account Passowrd Change</Button>
                </Box>
            </Box>
            <Box>
                <Typography>Two-step verification</Typography>
                <Typography sx={{
                    textAlign: 'center',
                    fontSize: '13px',
                    py: 1.4,
                    my: 1.5,
                    backgroundColor: '#F1F9FE',
                    color: '#54B0F2'
                }}>
                    You can manage your 2FA settings from the Google My Account page.
                </Typography>
               
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Button variant="contained" onClick={() => window.open('https://myaccount.google.com/signinoptions/two-step-verification', '_blank')}>Manage 2FA</Button>
                </Box>
            </Box>
        </Box>
    )
}