import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { getNestedError } from "../../../../components/forms/utils";

export default function MUIAutocomplete(props) {
    const { control, options, errors, required, fontSize, ...restProps } = props;

    const isError = !!getNestedError(errors, restProps.name);

    const variant = props.variant ? props.variant : 'outlined';

    var borderNone = {};
    if (props.border === false || props.border === "false") {
        borderNone = isError ? {} : {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: "none",
                    padding: 0,
                },
            }
        };
    }

    const backgroundColorWhenDisabled = props.disabled ? { backgroundColor: '#eee' } : {};
    const stt = fontSize ? {
        fontSize: fontSize ? '12px' : undefined, padding: 0
    } : {}

    return (
        <Controller
            name={restProps.name}
            control={control}
            defaultValue={null}
            rules={required ? { required: 'Value is required!' } : {}}
            render={({ field: { onChange, value } }) => {
                // Find the option object that matches the value
                const selectedOption = options.find(option => option.value === value);

                return (
                    <Autocomplete
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        options={options || []}
                        disabled={props.disabled}
                        // Use the selected option object instead of just its value
                        value={selectedOption || null}
                        onChange={(_, newValue) => {
                            onChange(newValue ? newValue.value : null)
                            // If props.onChange is defined, call it with the new value
                            if (props.onChange) {
                                props.onChange(newValue ? newValue.value : null)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant={variant}
                                fullWidth
                                error={required ? isError : false}
                                multiline={props.isMultiline}
                                sx={{ ...borderNone, ...backgroundColorWhenDisabled }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: stt,
                                }}
                            />
                        )}
                    />
                );
            }}
        />
    );
}
