import { Card } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useState } from "react";

import EditForm from "../Edit/EditForm";
import { useParams, Link, useNavigate } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import instance from "../../../auth/utils/useJwt";
import useSnackbar from "../../../components/Dialogs/SnackBar";


export default function PatientAdd() {
    const match = useParams()
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const { type } = useParams()
    console.log(type)
    const [apiReq, setApiReq] = useState({loading: false, error: null})
    const [intiail_data, setInitialData] = useState({
        first_name: '', last_name: '', dob: '', dob_text: '',
        languages: []
    })
    const navigate = useNavigate()
    const onSubmit = (data) => {
        console.log("New user")
        console.log(data)

        data.patient_type = type === 'accepted' ? "R" : "P"
        setInitialData(data)

        setApiReq({loading: true, error: null})
        instance.post("/patients", data).then(res => {
            showSnackbar('Patient created successfully', 'success')
            setApiReq({loading: false, error: null})
            setTimeout(() => {
                navigate(`/patients/${res.data.romac_id}`);
            }, 2000);
        }).catch(res => {
            console.log(res)
            setApiReq({loading: false, error: "Error upading user"})
            showSnackbar(`There is an error creating new patient`, 'error')
        })
    }

    const onCancel = () => {
        navigate("/patients")
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/patients" sx={{ color: '#111', fontSize: '15px' }}>
            Patients
        </Link>,
        <Typography key="3" color="text.primary" sx={{ color: "#09f", fontSize: '14px' }}>
            New
        </Typography>
    ];
    return (
        <AppLayout>
            
            <SnackbarComponent />
            <Box sx={{ my: 2 }}>
            <Breadcrumbs separator="|" aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Card>
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                    borderBottom: '1px solid #b8bfe3'
                }}>
                    <Box sx={{ pt: 0.5, px: 1 }}>
                        <Typography variant="h6">Add <span style={{ textTransform: 'capitalize' }}>{ match.type }</span> Patient</Typography>
                    </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                    <EditForm data={intiail_data} onSubmit={onSubmit} apiReq={apiReq} onCancel={onCancel} />
                </Box>
            </Card>
        </AppLayout>
    )
}