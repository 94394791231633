import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Security from './Tabs/Security';
import Notifications from './Tabs/Notifications';
import TimeZone from './Tabs/TimeZone';
import Preferences from './Tabs/Preferences';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MyTab() {
    return <h2>Ok</h2>
}


export default function Settings() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabData = [
        { label: 'Security', component: <Security /> },
        { label: 'Notifications', component: <Notifications /> },
        { label: 'TimeZone', component: <TimeZone /> },
        { label: 'Preferences', component: <Preferences /> }
    ]

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Patient Details">
                    {
                        tabData.map((item, index) => (
                            <Tab key={`tab-label-${index}`} label={item.label} {...a11yProps(index)} sx={{ textTransform: 'capitalize' }} />
                        ))
                    }

                </Tabs>
            </Box>
            {
                tabData.map((item, index) => (
                    <TabPanel key={`tab-${index}`} value={value} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </Box>
    );
}
