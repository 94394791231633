import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import './styles.css'
import CaseStatus from "./components/CaseStatus";
import { Box, Stack } from "@mui/material";
import StarPatientCase from "./components/StarPatientCase";

const get_romac_link = (id) => {
    // id can be in 2 formats R-1234 or R-1234-1
    // If it is R-1234
    if (id.length === 6) {
        return id.slice(0, 6)
    }
    const case_id = id.slice(id.length - 1, id.length)
    // if number is 1 digit, make it 2 digit with 0 prefix
    const case_id_2 = case_id.length === 1 ? "0" + case_id : case_id

    // Replace last - with "/cases/" -> Get directly to the case
    return id.slice(0, id.length - 3) + "/case/" + case_id_2

}

const COLUMNS = [
    {
        field: "starred",
        headerName: "",
        width: 40,
        renderCell: (params) => {
            return (
                <Box>
                <StarPatientCase case_id={params.row.case_id} patient_id={params.row.patient_id} 
                    starred={params.row.starred} romac_case_id={params.row.romac_id} />
                    </Box>
            )
        }
    },
    {
        field: "romac_id",
        headerName: "Case ID",
        renderCell: (params) => {
            return (
                <Link to={`/patients/${get_romac_link(params.value)}`} rel="noreferrer">
                    {params.value}
                </Link>
            )
        }
    },
    {
        field: "first_name",
        headerName: "First Name",
        width: 150
    },
    {
        field: "last_name",
        headerName: "Last Name",
        width: 150
    },
    {
        field: "dob",
        headerName: "DOB"
    },
    {
        field: "region",
        headerName: "Region"
    },
    {
        field: "country",
        headerName: "Country",
        width: 150
    },
    {
        field: "medical_classification",
        headerName: "Medical Classification",
        width: 190
    },
    {
        field: "action",
        headerName: "Current/Next Action",
        flex: 1,
        cellClassName: 'action-column',
        renderCell: (params) => {
            return (
                <i>{params.value}</i>
            )
        }
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
            return (
                <CaseStatus text={params.value} />
            )
        }
    }
]

const filterDataBySearch = (allData, searchInput) => {
    const searchQuery = searchInput.toLowerCase();
    return allData.filter(patient => {
        return (
            patient.romac_id?.toLowerCase().includes(searchQuery) ||
            patient.first_name?.toLowerCase().includes(searchQuery) ||
            patient.last_name?.toLowerCase().includes(searchQuery)
        );
    });
}


export { COLUMNS, filterDataBySearch }