import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layout/AppLayout";
import { Box, Typography, Grid, Card, CardHeader, CardContent, List, Paper } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactMarkdown from 'react-markdown';

import BContainer from "../../components/BContainer";
import BreadCrumbs from "../../components/BreadCrumbs";
import SingleDocx from "./SingleDocx";
import { styled } from "@mui/material";

// How it works
import HowItWorksHome from './data/how_it_works/home.md'
import HowItWorksOverview from './data/how_it_works/overview.md'

// Users and Roles
import UserRolesHome from './data/users_and_roles/home.md'
import UsersRolesEHRXeroRoles from './data/users_and_roles/ehr_xero_roles.md'
import UsersRolesUserResponsibilities from './data/users_and_roles/user_responsibilities.md'


import EIHome from './data/expense_invoices/home.md'
import EICaseInvoiceList from './data/expense_invoices/case_invoice_list.md'
import EIInvoiceFields from './data/expense_invoices/invoice_fields.md'
import EIStatusCodes from './data/expense_invoices/invoice_status_codes.md'
import EIExpenseItems from './data/expense_invoices/expense_items.md'
import EIDuplicateDetection from './data/expense_invoices/duplicate_detection.md'
import EIInvoiceNotes from './data/expense_invoices/invoice_notes.md'

import NotificationsHome from './data/notifications/home.md'
import GrantingAccessHome from './data/granting_access/home.md'

const CustomLi = styled('li')(({ theme }) => ({
    paddingLeft: '3px',
    listStyleType: 'none',
    paddingTop: '3px',
    paddingBottom: '3px',
    '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        textDecoration: 'underline'
    }
}))


export default function Documentation() {
    const contentRef = React.useRef(null);
    const { mod, submod, subsubmod } = useParams()
    const [subRoute, setSubRoute] = useState(null)
    const [md, setMd] = useState(null)
    const [toc, setToc] = useState(null)
    const [selectedToc, setSelectedToc] = useState(null)
    const [breadcrumbs, setBreadcrumbs] = useState([
        { text: "Documentation", url: "/docx" },
    ])

    const ITEMS = [
        "how-it-works", "users-and-roles", "expense-invoices", "notifications", "granting-access",
      
    ]

    const DOCX_ITEMS = {
        'how-it-works': {
            title: "How it works",
            description: "How it works",
            md: HowItWorksHome,
            pages: [
                { "title": "Overview", url: "overview", md: HowItWorksOverview },
            ]
        },
        'users-and-roles': {
            title: "Users and Roles",
            description: "Users and Roles",
            md: UserRolesHome,
            pages: [
                { "title": "eHR Xero Roles", url: "ehr-xero-roles", md: UsersRolesEHRXeroRoles },
                { "title": "User Responsibilities", url: "user-responsibilities", md: UsersRolesUserResponsibilities },
            ]
        },
        'expense-invoices' : {
            title: "Expense Invoices",
            description: "Expense Invoices",
            md: EIHome,
            pages: [
                { "title": "Invoice Fields", url: "invoice-fields", md: EIInvoiceFields },
                { "title": "Case Invoice List", url: "case-invoice-list", md: EICaseInvoiceList },
                { "title": "Invoice Status Codes", url: "invoice-status-codes", md: EIStatusCodes },
                { "title": "Expense Items", url: "expense-items", md: EIExpenseItems },
                { "title": "Duplicate Detection", url: "duplicate-detection", md: EIDuplicateDetection },
                { "title": "Invoice Notes", url: "invoice-notes", md: EIInvoiceNotes },
            ]
        },
        'notifications': {
            title: "Notifications",
            description: "Notifications",
            md: NotificationsHome,
            pages: []
        },
        'granting-access': {
            title: "Granting Access",
            description: "Granting Access",
            md: GrantingAccessHome,
            pages: []
        },
    }

    const navigate = useNavigate()
    useEffect(() => {
        // Concat with seperator where value is not undefined
        const url = "/docx/" + [mod, submod, subsubmod].filter(Boolean).join('/')
        console.log(url)
        const sub_url = [mod, submod, subsubmod].filter(Boolean).join('/')
        const base_breadcrumb = [{ text: "Documentation", url: "/docx" }]

        if (url === "/docx/") {
            setMd(null)
            setSubRoute(null)
        } else if (mod && !submod) {
            // Create markdown based on title, description and pages
            // setMd(DOCX_ITEMS[mod]?.md)
            
            // fetch(DOCX_ITEMS[mod]?.md).then(res => res.text()).then(text => setMd(text))
            navigate("/docx")
        }
        else {
            // console.log(mod, submod)
            
            fetch(DOCX_ITEMS[submod]?.md).then(res => res.text()).then(text => setMd(text))
            // DOCX_ITEMS[submod]?.pages?.forEach(item => {
            //     if (item.url === submod) {
            //         fetch(item.md).then(res => res.text()).then(text => setMd(text))
            //     }
            // })
        }

        setBreadcrumbs(base_breadcrumb.concat([mod, submod, subsubmod].filter(Boolean).map((item, index) => {
            return { text: item.replaceAll("-", " "), url: "/docx/" + [mod, submod, subsubmod].filter(Boolean).slice(0, index + 1).join('/') }
        })))

    }, [mod, submod, subsubmod])


    const scrollToHeading = (id) => {
        document.getElementById(id)?.scrollIntoView();
    };

    const handleScroll = () => {
        console.log('Scrolling...');
        const topMostHeading = toc.find(heading => {
            const element = document.getElementById(heading.id);
            if (element) {
                const bounding = element.getBoundingClientRect();
                return bounding.top >= 0 && bounding.top <= window.innerHeight / 2;
            }
            return false;
        });

        if (topMostHeading) {
            setSelectedToc && setSelectedToc(topMostHeading.id);
        }
    };

    // Get any # in URL and scroll to that heading
    const hash = window.location.hash;
    const hashId = hash ? hash.substring(1) : null;
    console.log(hashId)

    useEffect(() => {
        const container = contentRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [toc, contentRef]);

    useEffect(() => {
        if (hashId && contentRef.current) {
            scrollToHeading(hashId);
            setSelectedToc(hashId)
        }
    }, [hashId, toc]);


    return (
        <AppLayout title="Documentation" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs} />
            </BContainer>
            <Box sx={{
                px: 2, pb: 2,
                height: 'calc(100vh - 160px)'
                , overflowY: 'auto'
            }} ref={contentRef}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        { md ? 
                                <SingleDocx md={md} toc={toc} setToc={setToc}
                                    setSelectedToc={setSelectedToc}
                                />
 :
                        <Card sx={{ mt: 1 }}>
                            {/* { !md &&
                            <CardHeader title="ROMAC Documentation" sx={{ borderBottom: '1px solid #ddd', backgroundColor: "#E8F2F8" }} />
                            } */}
                            <CardContent sx={{ px: 5 }}>
                                {!md &&
                                    <Box sx={{ pt: 2 }}>
                                        {/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Documentation</Typography> */}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{"XERO Integration"}</Typography>
                                        {
                                            ITEMS.map((item, index) => (
                                                <Box>
                                                    <Box>
                                                        <Paper elevation={0} sx={{ mt: 2 }}>
                                                            {/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{DOCX_ITEMS[item].title}</Typography> */}
                                                            <Link style={{ color: "#09f", textDecoration: 'none' }} to={`/docx/xero-integration/${item}`}>
                                                                        
                                                                        
                                                                            {DOCX_ITEMS[item].title}
                                                                        
                                                                    </Link>
                                                            {/* <Typography variant="caption" sx={{ color: '#666' }}>{DOCX_ITEMS[item].description}</Typography> */}
                                                        </Paper>
                                                        <Grid container columnSpacing={2}>
                                                            {/* {DOCX_ITEMS[item].pages.map((page, index) => (
                                                                <Grid item xs={12} md={12}>
                                                                    <Link style={{ color: "#09f" }} to={`/docx/xero-integration/${item}#${page.url.toLowerCase().replace(" ", "-")}`} style={{ textDecoration: 'none' }}>
                                                                        
                                                                        
                                                                            {page.title}
                                                                        
                                                                    </Link>
                                                                </Grid>
                                                            ))} */}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            ))
                                        }




                                    </Box>
                                }

                            </CardContent>
                        </Card>
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* If page is not /docx */}
                        { window.location.pathname !== "/docx" &&
                        <Box sx={{ borderLeft: '4px solid #06f', borderTopLeftRadius: '2px', pl: 2, mt: 2, position: 'sticky', top: '0px' }}>
                            <Typography sx={{}}>On This page</Typography>

                            <Box>
                                <ul style={{ paddingLeft: '3px', listStyleType: 'none' }}>
                                    {toc && toc.map((heading, index) => (
                                        <CustomLi
                                            sx={{ color: selectedToc === heading.id ? '#06f' : '#222' }}
                                            key={index} onClick={() => scrollToHeading(heading.id)}>
                                            {heading.text}
                                        </CustomLi>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
}
                    </Grid>
                </Grid>
            </Box>
        </AppLayout >
    )
}