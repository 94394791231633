import * as React from 'react';
import { Box, Checkbox } from '@mui/material';
import instance from '../../../../auth/utils/useJwt';
import { fetchUsers } from '../../../../redux/users';
import { useDispatch } from 'react-redux';

export default function UserEnable({
    id, enabled
}) {

    const [processing, setProcessing] = React.useState(true)
    const dispatch = useDispatch()

    const onChangeAccess = () => {
        setProcessing(true)
        instance.patch("/admin/users", { "id": id, "enabled": !enabled }).then(res => {
            dispatch(fetchUsers())
        }).catch(err => {
            console.log(err)
            setProcessing(false)
        })
    }

    return (
        <Box>
            <Checkbox checked={enabled} onClick={onChangeAccess} size="small" />
        </Box>

    );
}



