import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import instance from "../../../../auth/utils/useJwt";
import Button from '@mui/material/Button'
import { styled } from '@mui/system';

import { useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { Box, DialogContentText, LinearProgress, Stack, Typography } from '@mui/material';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';


export default function ExportPatient(props) {
    const { open, setOpen } = props
    const [response, setResponse] = useState(null)

    const handleClose = () => {
        setOpen(false)
    }

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const { patient_id, case_id } = useParams()

    const onSubmit = () => {
        setApiReq({ loading: true, error: null })
        const data = { id: patient_id }
        instance.post(`/patients/export?id=${patient_id}`, data).then(res => {
            console.log("Done")

            //props.setOpen(false)
            setApiReq({ loading: false, error: null })
            setResponse(res.data)
            window.open(res.data, '_blank', 'noreferrer')
        }).catch(err => {
            console.log("Error")
            setApiReq({ loading: false, error: null })
        })
    }

    const onError = (error) => {

    }



    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Export Patient Data"
            disabled={apiReq.loading}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Close</Button>

                    {!response &&
                        <Button onClick={onSubmit} disabled={apiReq.loading} variant="contained">
                            {
                                apiReq.loading ? `EXPORTING` : `EXPORT`
                            }
                        </Button>
                    }
                </Stack>
            }>
            <DialogContentText sx={{ py: 1 }}>

                {!response &&
                    <span>Export data to spreadsheet</span>
                }

                {
                    apiReq.loading &&
                    <Box>
                        <Typography sx={{ mb: 1 }}>Please wait, this may take a moment</Typography>
                        <LinearProgress />

                    </Box>
                }
                {
                    response &&
                    <Box>
                        <Typography variant="body">
                            Export completed. View in <a href={response} target="_blank">Google Sheets</a>
                        </Typography>
                    </Box>
                }

            </DialogContentText>
        </GeneralDialog>
    )
}