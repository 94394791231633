import * as React from 'react';
import { IconButton, ListItemButton, Button, Stack, Typography, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import instance from '../../../../../auth/utils/useJwt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import AppsIcon from '@mui/icons-material/Apps';
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';
import { useNavigate } from 'react-router-dom';
export default function SuspendUser({
    id, enabled
}) {
    const [apiReq, setApiReq] = React.useState({
        open: false, processing: false
    })

    const navigate = useNavigate()
    const onAction = () => {

        setApiReq({ ...apiReq, processing: true })
        instance.patch("/admin/users", { "id": id, "enabled": !enabled }).then(res => {
            setApiReq({ open: false, processing: true })
            navigate('/admin/users')
        }).catch(err => {
            setApiReq({ open: false, processing: true })
        })
    }

    const handleClose = () => {
        setApiReq({ open: false, processing: false })
    }
    const titleBf = enabled ? 'Suspend' : 'Enable'

    return (
        <>
            <ListItemButton onClick={() => {
                setApiReq({ open: true, processing: false })
            }}>
                {titleBf} User
            </ListItemButton>

            <GeneralDialog open={apiReq.open}
                title={`${titleBf} User`} handleClose={handleClose}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                        <Button onClick={onAction} variant="contained" color="primary" autoFocus disabled={apiReq.processing}>
                            { apiReq.processing ? 'Processing...' : titleBf }
                        </Button>
                    </Stack>
                }>
                    { enabled ?
                <Box>
                    <Typography>Some things to bear in mind:</Typography>
                    <List sx={{ mt: 2 }}>
                        {
                            [
                                [
                                    "User Licence fees still apply to the suspended users.",
                                    <CreditCardIcon />,
                                ],
                                [
                                    "This user's data will be kept, but they won't receive emails, calendar invitations or files as long as they're suspended.",
                                    <AppsIcon />
                                ],
                                [
                                    "Suspended users can be restored as long as they're not deleted.",
                                    <InfoIcon />
                                ]
                            ].map((item, index) => (
                                <ListItem key={index} sx={{ my: 0, py: 0 }}>
                                    <ListItemIcon>
                                        {item[1]}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {item[0]}
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
                :
                <Typography>
                    This user will be able to access their account and receive emails, calendar invitations and files.
                </Typography>
                }
            </GeneralDialog>

        </>

    );
}



