import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CaseHistory from './Tabs/CaseHistory';
import Medical from './Tabs/Medical';
import Guardians from './Tabs/Guardians';
import Background from './Tabs/Background';
import Referrer from './Tabs/Referrer';
import PatientFiles from './Tabs/PatientFiles';
import { useSearchParams } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function PatientTabs(props) {
    //const [value, setValue] = React.useState(0);
    const [searchParams] = useSearchParams();
    const { romac_id, patient_id } = props
    const isApproved = romac_id.slice(0, 1) === "R"

    const tabData = isApproved ? [
        { label: 'Case History', component: <CaseHistory id={props.patient_id} /> },
        { label: 'Guardians', component: <Guardians id={props.patient_id} /> },
        { label: 'Patient Files', component: <PatientFiles data={props.data} /> },
        { label: 'Referrer', component: <Referrer id={props.patient_id} /> },
        { label: 'Medical', component: <Medical id={props.patient_id} /> },
        { label: 'Background', component: <Background id={props.patient_id} /> }
    ] : [
        { label: 'Guardians', component: <Guardians id={props.patient_id} /> },
        { label: 'Referrer', component: <Referrer id={props.patient_id} /> },
        { label: 'Medical', component: <Medical id={props.patient_id} /> },
        { label: 'Background', component: <Background id={props.patient_id} /> }
    ]

    const tabFromQuery = searchParams.get("tab");
    const tabIndex = tabData.findIndex(tab => tab.label === tabFromQuery);
    const initialTab = tabIndex !== -1 ? tabIndex : 0;

    const [value, setValue] = React.useState(initialTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Patient Details">
                    {
                        tabData.map((item, index) => (
                            <Tab key={`tab-label-${index}`} label={item.label} {...a11yProps(index)} sx={{ textTransform: 'capitalize' }} />
                        ))
                    }

                </Tabs>
            </Box>
            {
                tabData.map((item, index) => (
                    <TabPanel key={`tab-${index}`} value={value} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </Box>
    );
}
