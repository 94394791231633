import { IconButton, Typography } from "@mui/material";
import Box from '@mui/material/Box'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaginationPageSelect from "./PaginationPageSelect";

export default function CustomPagination({
    onChangePage, totalPages, currentPage
}) {


    const handleChangePage = (e, v) => {
        if (v < 0 || v > totalPages) return
        onChangePage(v)
    }


    return (

        <Box sx={{ display: 'flex', fontSize: '.8em' }}>
            <IconButton size="small" color="primary" disabled={currentPage === 0} onClick={(e) => handleChangePage(e, currentPage - 1)}>
                <ArrowBackIcon />
            </IconButton>
            {/* <Typography sx={{ alignSelf: 'center', mx: 1, fontSize: '1em',
                // disable text selection
                userSelect: 'none'
             }}>
                Page {currentPage+1} of {totalPages}
                </Typography> */}
                <PaginationPageSelect onChangePage={onChangePage} totalPages={totalPages} currentPage={currentPage} />
            <IconButton size="small" color="primary" disabled={currentPage === totalPages-1} onClick={(e) => handleChangePage(e, currentPage + 1)}>
                <ArrowForwardIcon />
            </IconButton>

        </Box>

    )
}