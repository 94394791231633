import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { Avatar, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


import { Link } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
const TD = styled("td")(({ theme }) => ({
  padding: '.5rem',
  fontSize: '0.9rem',
  // first cell width 50%, rest 25% for 2 cells
  width: '50%',
  // '&:first-child': {
  //   width: '50%',
  // },
}));

const TR = styled("tr")(({ theme }) => ({
  // borderBottom: '1px solid #555',
  marginBottom: '8px',
  '&:last-child': {
    borderBottom: 'none'
  }
}));

const StatisticCard = (props) => {
  const { title, value, IconComponent, color, data } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{
      minWidth: 275,
      // bgcolor: color.background, color: color.text 
    }}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 2 }}>
          {/* <Grid container spacing={2} alignItems="center" justifyContent="center">
            {IconComponent && (
              <Grid item>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconComponent style={{ fontSize: '2rem', color: color.icon }} />
                </Box>
              </Grid>
            )}

            <Grid item xs>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {title}
              </Typography>
              <Typography variant="h5" component="div">
                {value || 0}
              </Typography>
            </Grid> 
          </Grid>
          
          {data && data.length > 0 &&
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
          */}

          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            </IconButton>
            <Typography sx={{ fontSize: 19, fontWeight: 500, mt: 0.8 }} gutterBottom>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 18, fontWeight: 500, mt: 0.8 }} gutterBottom>
              {value || 0} Patients
            </Typography>
          </Box>


        </Box>
      </Box>
      {
        open &&
        <Box sx={{ backgroundColor: "#fff", px: 1, mt: 1 }}>
          {/* Show data as table (romac_id, first_name, last_name) */}
          <table style={{ width: '100%' }}>
            <tbody>
              {
                data.map((item, index) => (
                  <TR key={index}>
                    <TD>
                      <Box sx={{ display: 'flex', gap: 2, fontWeight: 450 }}>
                        {/* <Avatar sx={{ width: '30px', height: '30px', fontSize: '12px', mr: 1 }} src={item.avatar} alt={item.first_name} /> */}

                        <Link to={`/patients/${item.romac_id}`} style={{ textDecoration: 'none' }}>
                          {item.romac_id}
                        </Link>
                        <span style={{ color: "#333" }}>{item.first_name} {item.last_name}</span>
                        <span style={{ color: "#888" }}>({item.country})</span>
                      </Box></TD>
                    <TD style={{ textAlign: 'right' }}>{item.referral_date}</TD>
                  </TR>
                ))
              }
            </tbody>
          </table>
        </Box>
      }
    </Box>
  );
};

export default StatisticCard;
