import { Button, Checkbox } from "@mui/material";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import SelectAutocomplete from "./form_comps/SelectAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchRegions } from "../../../redux/region";
import { fetchMedicalClassifications } from "../../../redux/medical/classification";
import { fetchCountries } from "../../../redux/country";
import { fetchHospitals } from "../../../redux/medical/hospital";
import { fetchDistricts } from "../../../redux/districts";
import { fetchClinicians } from "../../../redux/medical/clinicians";
import DatePicker from "../../../components/forms/DatePicker";

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function FiltersForm(props) {
    const { filters, setFilters, loading } = props
    const dispatch = useDispatch()

    const STATUS_OPTIONS = [
        { label: "", value: "" },
        { label: "ACCEPTED", value: "ACCEPTED" },
        { label: "ASSISTED", value: "ASSISTED" },
        { label: "BY_OTHERS", value: "BY_OTHERS" },
        { label: "COMPLETED", value: "COMPLETED" },
        { label: "CURRENT", value: "CURRENT" },
        { label: "DECEASED", value: "DECEASED" },
        { label: "FACILITATED", value: "FACILITATED" },
        { label: "INVALID_OPS", value: "INVALID_OPS" },
        { label: "MISSING", value: "MISSING" },
        { label: "MONITOR", value: "MONITOR" },
        { label: "PENDING", value: "PENDING" },
        { label: "REFERRAL", value: "REFERRAL" },
        { label: "REFERRED", value: "REFERRED" },
        { label: "REJECTED", value: "REJECTED" }
    ]


    const onChangeInput = (input_name, input_value) => {
        setFilters({ ...filters, [input_name]: input_value })
    }
    const regions = useSelector(state => state.regions).data
    const classifications = useSelector(state => state.classifications).data
    const countries = useSelector(state => state.countries).data
    const hospitals = useSelector(state => state.hospitals).data
    const districts = useSelector(state => state.districts).data
    const clinicians = useSelector(state => state.clinicians).data

    useEffect(() => {
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
        if (classifications.length === 0) {
            dispatch(fetchMedicalClassifications())
        }
        if (countries.length === 0) {
            dispatch(fetchCountries())
        }
        if (hospitals.length === 0) {
            dispatch(fetchHospitals())
        }
        if (districts.length === 0) {
            dispatch(fetchDistricts())
        }
        if (clinicians.length === 0) {
            dispatch(fetchClinicians())
        }
    }, [])

    return (
        <Box>
            <Box sx={{ mb: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <CustomInputLabel>Date</CustomInputLabel>
                        <SelectAutocomplete options={
                            [
                                { label: "REFERRAL", value: "REFERRAL" },
                                { label: "ARRIVAL", value: "ARRIVAL" },
                                { label: "DEPARTURE", value: "DEPARTURE" },
                            ]
                        } value={filters.date} onChange={onChangeInput} name="date" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>From</CustomInputLabel>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                defaultValue={dayjs(dayjs().subtract(8, 'month').format('MM-DD-YYYY'))}
                                onChange={(date) => onChangeInput("from", dayjs(date).format('MM-DD-YYYY'))}
                            />
                        </LocalizationProvider> */}
                        <DatePicker value={filters.from} onChange={(e) => onChangeInput("from", e.value)} />
                    </Grid>

                    <Grid item md={3}>
                        <CustomInputLabel>To</CustomInputLabel>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                defaultValue={dayjs(dayjs().format('MM-DD-YYYY'))}
                                onChange={(date) => onChangeInput("to", dayjs(date).format('MM-DD-YYYY'))}
                            />
                        </LocalizationProvider> */}
                        
                        <DatePicker value={filters.to} onChange={(e) => onChangeInput("to", e.value)} />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>Status</CustomInputLabel>
                        <SelectAutocomplete options={STATUS_OPTIONS} value={filters.status} onChange={onChangeInput} name="status" />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item md={3}>
                        <CustomInputLabel>Region</CustomInputLabel>
                        <SelectAutocomplete options={
                            regions.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.region} onChange={onChangeInput} name="region" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>District</CustomInputLabel>
                        <SelectAutocomplete options={
                            districts.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.district} onChange={onChangeInput} name="district" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>Condition</CustomInputLabel>
                        <SelectAutocomplete options={
                            classifications.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.condition} onChange={onChangeInput} name="condition" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>Country</CustomInputLabel>
                        <SelectAutocomplete options={
                            countries.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.country} onChange={onChangeInput} name="country" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>Hospital</CustomInputLabel>
                        <SelectAutocomplete options={
                            hospitals.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.hospital} onChange={onChangeInput} name="hospital" />
                    </Grid>
                    <Grid item md={3}>
                        <CustomInputLabel>Clinician</CustomInputLabel>
                        <SelectAutocomplete options={
                            clinicians.map(({ id, name }) => ({ label: name, value: id }))
                        } value={filters.clinician} onChange={onChangeInput} name="clinician" />

                    </Grid>
                    <Grid item>
                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Checkbox onChange={(e) => onChangeInput('visa_expiry', e.target.checked)} value={filters.visa_expiry} />
                            <InputLabel sx={{ fontSize: '13px', mt: 1.5 }}>Visa Expiry</InputLabel>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: '10px' }}>
                {/* <Button variant="outlined" disabled={loading} color="secondary">Add Fields</Button> */}
                <Button variant="outlined" disabled={loading} onClick={props.onClear}>Clear</Button>
                <Button variant="contained" disabled={loading} onClick={() => props.fetchData()}>{
                    loading ? 'Loading...' : 'Update'
                }</Button>
            </Box>
        </Box>
    )
}