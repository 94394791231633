import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { ListItemText } from "@mui/material";

export default function MUIAutocompleteMultipleCheck(props) {
    const { register, control, options, errors, chips, ...restProps } = props;
    const isError = errors && errors[restProps.name];

    return (
        <Controller
            name={restProps.name}
            control={control}
            defaultValue={restProps.defaultValue || []}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    multiple
                    options={options || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    disableCloseOnSelect
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            chips ? 
                            <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                size="small"
                            />
                            :
                            <span key={index}>
                                {option.label}
                                { index < tagValue.length - 1 ? ', ' : '' }
                            </span>
                        ))
                    }
                    getOptionLabel={(option) => option.label}
                    value={value}
                    onChange={(_, newValue) => onChange(newValue)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                checked={value.some(val => val.value === option.value)}
                                style={{ marginRight: 8 }}
                            />
                            <ListItemText primary={option.label} />
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} size="small" variant="outlined" fullWidth />
                    )}
                    onClose={(event, reason) => {
                        if (reason === 'escape') {
                            return;
                        }
                    }}
                />
            )}
        />
    );
}
