import instance from "../../../../auth/utils/useJwt";

import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom';

import { useState } from 'react';
import { useForm } from "react-hook-form";
import { Box, DialogContentText, LinearProgress, Stack } from '@mui/material';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';



export default function AcceptReferralPatientDialog(props) {

    const { open, setOpen } = props

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const navigate = useNavigate()
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    const { patient_id } = useParams()

    const onSubmit = () => {
        setApiReq({ loading: true, error: null })
        instance.post("/patients/change-status", { "patient_id": props.patient_id }).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            navigate(`/patients/${res.data}`)
        }).catch(err => {
            console.log(err)
            setApiReq({ loading: false, error: "Error accepting patient" })
        })
    }

    const onError = (error) => {
        //alert("error")
    }

    const handleClose = () => {
        setOpen(false)
    }


    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Approve Patient"
            disabled={apiReq.loading} onFormSubmit={handleSubmit(onSubmit, onError)}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button type="submit" disabled={apiReq.loading} variant="contained">
                        {
                            apiReq.loading ? `Approving` : `Approve AS ROMAC Patient`
                        }
                    </Button>
                </Stack>
            }>
            {!apiReq.loading ?
                <DialogContentText sx={{ my: 1 }}>
                    Approve this referral as a new ROMAC Patient "<b>{patient_id}</b>"
                </DialogContentText>
                :
                <Box>
                    <DialogContentText sx={{ my: 1 }}>
                        Approving "<b>{patient_id}</b>". This may take a while
                    </DialogContentText>

                    <LinearProgress color="primary" sx={{ my: 1 }} />
                </Box>
            }

        </GeneralDialog>
    )
}