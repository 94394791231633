import * as React from 'react';
import { Checkbox } from '@mui/material';
import instance from '../../../../auth/utils/useJwt';
import { fetchUsers } from '../../../../redux/users';
import { useDispatch } from 'react-redux';

export default function ChangeUserCol({
    id, field, value
}) {

    const [processing, setProcessing] = React.useState(true)
    const dispatch = useDispatch()

    const onChangeAccess = () => {
        setProcessing(true)
        instance.patch("/admin/users", { "id": id, [field]: !value }).then(res => {
            dispatch(fetchUsers())
        }).catch(err => {
            setProcessing(false)
        })
    }

    if (id === 4) {
        console.log("id", id, "field", field, "val", value)
    }

    return (
        <Checkbox checked={value} onClick={onChangeAccess} size="small" name="checkedA" inputProps={{ 'aria-label': 'secondary checkbox' }} />
    );
}



