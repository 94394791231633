// ** React Imports
import { Navigate, Outlet } from "react-router-dom"
import { isUserLoggedIn } from "../auth/utils" 
import { useSelector } from "react-redux"

const AdminRoute = (props) => {
const user = useSelector(state => state.user)

  const userLoggedIn = isUserLoggedIn()
  if (userLoggedIn) {
    if (user.role?.type !== "A"){
        return <Navigate to={"/"} />
    }
    return <Outlet />
  }else{
    return <Navigate to={"/login"} />
  }
}


export default AdminRoute