import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useParams } from 'react-router-dom';

import instance from '../../../../auth/utils/useJwt';

import { Button, LinearProgress } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicalClassifications } from '../../../../redux/medical/classification';
import DatePicker from '../../../../components/forms/DatePicker';
import { hasWritePermission } from '../../../../redux/user';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';
import MuiDataGrid from '../../../../components/tables/MUIDataGrid';

export default function CaseHistory(props) {
  const [newCaseOpen, setNewCaseOpen] = useState(false)
  const canWrite = useSelector(hasWritePermission);
  const [loading, setLoading] = useState(false)
  const [caseData, setCaseData] = useState([])
  const { patient_id } = useParams()
  

  const fetchCases = () => {
    setNewCaseOpen(false)
    setLoading(true)
    instance.get(`/cases?id=${props.id}`).then(res => {
      setCaseData(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  useEffect(() => {
    // if patient id starts with "R-", only then fetch cases
    if (patient_id.startsWith("R-"))
      fetchCases()
  }, [])


  
  return (
    <Box>
      {loading &&
        <Box>
          <LinearProgress />
        </Box>}
        {/* <MuiDataGrid data={caseData} loading={loading} columns={INVOICE_COLUMNS} */}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead sx={{ backgroundColor: '#f6f6f7' }}>
            <TableRow>
              <TableCell>Case ID</TableCell>
              <TableCell align="right">Referral Date</TableCell>
              <TableCell align="right">Classification</TableCell>
              <TableCell align="right">Case Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caseData.map((row) => (
              <TableRow
                key={row.case_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Link style={{ textDecoration: 'none' }} to={`/patients/${patient_id}/case/${row.case_id}`}> {patient_id}-{row.case_id.toString().padStart(2, '0')}</Link>
                </TableCell>
                <TableCell align="right" sx={{ py: 1.1 }}>{row.referral_date}</TableCell>
                <TableCell align="right">{row.classification}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

          { canWrite &&
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant='contained' size="small" onClick={() => setNewCaseOpen(true)}>New Case</Button>
      </Box>}
      <NewCase open={newCaseOpen} handleClose={() => setNewCaseOpen(false)} patient_id={props.id} onNew={fetchCases} />
    </Box>
  );
}



function NewCase(props) {
  const { open, handleClose, patient_id, onNew } = props
  const [apiReq, setApiReq] = useState({
    loading: false, error: null
  })

  const classifications = useSelector(state => state.classifications).data
  const dispatch = useDispatch()

  useEffect(() => {
    if (classifications.length === 0) {
      dispatch(fetchMedicalClassifications())
    }
  }, [])
  const [newCaseData, setNewCaseData] = useState({
    date: null, classification: null, patient_id: patient_id
  })

  const addNewCase = () => {

    setApiReq({ loading: true, error: null })
    instance.post("/cases", newCaseData).then(res => {


      setApiReq({ loading: false, error: null })
      onNew()
    }).catch(err => {
      console.log(err)


      setApiReq({ loading: false, error: "Error adding new course" })
    })
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{
        borderBottom: '1px solid #ddd'
      }}>
        {"Edit"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <CustomInputLabel>Referral Date</CustomInputLabel>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                  format={DATE_FORMAT}
                  onChange={(e) => setNewCaseData({ ...newCaseData, date: formatDate(e.$d) })} slotProps={{ textField: { size: 'small' } }} />
                </LocalizationProvider> */}
                <DatePicker name="date" value={newCaseData.date} onChange={(data) => setNewCaseData({ ...newCaseData, date: data.value })} />
              </Grid>
              <Grid item md={6}>
                <CustomInputLabel>Classification</CustomInputLabel>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={newCaseData.classification}
                    onChange={(e) => setNewCaseData({ ...newCaseData, classification: e.target.value })}
                  >

                    <MenuItem value={null}>Select Classification</MenuItem>
                    {classifications.map((item) => (
                      <MenuItem key={`clsf-${item.id}`} value={item.id}>{item.name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>

            </Grid>
          </Box>



        </Box>

      </DialogContent>
      <DialogActions sx={{ pb: 3, pr: 3 }}>
        <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
        <Button onClick={addNewCase} disabled={apiReq.loading} variant="contained">SAVE</Button>
      </DialogActions>
    </Dialog>
  )
}