import { Button, Card, Checkbox } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from 'react-hook-form';
import { fetchCountries } from "../../../redux/country";
import { fetchRegions } from "../../../redux/region";
import MUIAutocomplete from "../../../components/forms/MUIAutocomplete";
import instance from "../../../auth/utils/useJwt";
import { fetchLanguages } from "../../../redux/general/language";
import MUIAutocompleteMultiple from "../../../components/forms/MUIAutocompleteMultiple";

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
  }));
const GENDERS = [
    { label: "Male", value: 0 },
    { label: "Female", value: 1 },
]

const SPECIALIZATIONS = [
    { label: "Doctor", value: 'Doctor' },
    { label: "Member", value: 'Member' },
]

export default function EditProfileData(props) {
    const { open, handleClose, userData } = props
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    const [apiReq, setApiReq] = useState({loading: false, error: null})
    const dispatch = useDispatch()

    const languages = useSelector(state => state.languages).data
    const regions = useSelector(state => state.regions).data
    const countries = useSelector(state => state.countries).data
   useEffect(() => {
    if (regions.length === 0) {
        dispatch(fetchRegions())
    }
    if (countries.length === 0) {
        dispatch(fetchCountries())
    }
    if (languages.length === 0) {
        dispatch(fetchLanguages())
    }
   }, [])

    const getGeneralList = (countryId, countriesArray) => {
        const country = countriesArray.find(c => c.name === countryId);
        return country ? { label: country.name, value: country.id } : null;
    };

    const getParsedList = (countryId, countriesArray) => {
        const country = countriesArray.find(c => c.value === countryId);
        return country ? { label: country.label, value: country.value } : null;
    };
    
    useEffect(() => {
        if(userData) {
            reset({
                "first_name" : userData.first_name, last_name: userData.last_name, 
                country: getGeneralList(userData.country, countries), region: getGeneralList(userData.region, regions),
                gender: getParsedList(userData.gender, GENDERS),
                specialization: getParsedList(userData.specialization, SPECIALIZATIONS),
                phone: userData.phone, email: userData.email,
                languages: userData.languages ? userData.languages: []
            })
        }
        
    }, [userData])

    const onSubmit = (data) => {
        setApiReq({loading: true, error: null})
        const update_data = {
            first_name: data.first_name, last_name: data.last_name, phone: data.phone,
            country: data.country?.value? data.country.value : data.country,
            region: data.region?.value? data.region.value : data.region,
            gender: ![null, undefined].includes(data.gender?.value) ? data.gender.value : data.gender,
            specialization: data.specialization?.value? data.specialization.value : data.specialization,
            languages: data.languages
        }
        
        instance.patch("/user", update_data).then(res => {
            console.log(res)
            
        setApiReq({loading: false, error: null})
        props.onUpdate()
        }).catch(err => {
            console.log(err)
            
        setApiReq({loading: false, error: null})
        })
    }

    const onError = (error_data) => {

    }

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogTitle id="alert-dialog-title" sx={{
            borderBottom: '1px solid #ddd'
        }}>
          {"Edit"}
        </DialogTitle>
        <DialogContent>
        <Box sx={{ pt: 2 }}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <CustomInputLabel>First Name</CustomInputLabel>
                        <TextField size="small" {...register('first_name')} name="first_name" error={Boolean(errors.first_name )} fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Last Name</CustomInputLabel>
                        <TextField size="small" {...register('last_name')} name="last_name"  error={Boolean(errors.last_name )} fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Country</CustomInputLabel>
                        
                        <MUIAutocomplete errors={errors} control={control} name="country"
                        options={countries.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Region</CustomInputLabel>
                        <MUIAutocomplete errors={errors} control={control} name="region"
                        options={regions.map(({ id, name }) => ({ label: name, value: id }))} />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Email</CustomInputLabel>
                        <TextField size="small" {...register('email')} name="email" disabled error={Boolean(errors.email )} fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Phone</CustomInputLabel>
                        <TextField size="small" {...register('phone')} name="phone"  error={Boolean(errors.phone )} fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Gender</CustomInputLabel>
                        <MUIAutocomplete errors={errors} control={control} name="gender"
                        options={GENDERS} />
                    </Grid>
                    <Grid item md={6}>
                        <CustomInputLabel>Specialization</CustomInputLabel>
                        <MUIAutocomplete errors={errors} control={control} name="specialization"
                        options={SPECIALIZATIONS} />
                    </Grid>
                    <Grid item md={12}>
                        <CustomInputLabel>Languages</CustomInputLabel>
                        <MUIAutocompleteMultiple errors={errors} control={control} name="languages"
                        options={languages.map(({ id, name }) => ({ label: name, value: id }))} />
                    </Grid>
                    
                </Grid>
            </Box>
           
           
            
        </Box>
        
        </DialogContent>
        <DialogActions sx={{ pb: 3,pr: 3 }}>
                <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                <Button type="submit" disabled={apiReq.loading} variant="contained">{
                    apiReq.loading ? 'SAVING' : 'SAVE'
}</Button>
        </DialogActions>
        </form>
      </Dialog>
    )
}