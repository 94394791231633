import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import instance from '../../../../../auth/utils/useJwt';
import { Card } from '@mui/material';
import { useParams } from "react-router-dom";
import MuiDataGrid from '../../../../../components/tables/MUIDataGrid';
import { INVOICE_COLUMNS } from './utilities';
import GeneralDropdownFilter from '../../../../../components/forms/filters/GeneralDropdownFilter';
import MetaHeader from './MetaHeader';
export default function XeroExpenditure() {
    //const { data, loading } = useSelector(state => state.patient_case)
    const { patient_id, case_id } = useParams()
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [apiReq, setApiReq] = useState({ loading: false, error: null })
    const [romacCaseId, setRomacCaseId] = useState(null)
    const [invoice, setInvoice] = useState([])

    const fetchInvoice = () => {
        setApiReq({ loading: true, error: null })
        const case_romac_id = `${patient_id}-${String(case_id).padStart(2, '0')}`
        setRomacCaseId(case_romac_id)
        instance.get(`/case/xero-invoices?case_id=${case_romac_id}`).then(({ data }) => {
            // 
            setInvoice(data.Invoices.map((item, index) => {
                return {
                    ...item, id: index
                }
            }))
            setApiReq({ loading: false, error: null })
        }
        ).catch(err => {
            console.log(err)
            setApiReq({ loading: false, error: null })
        })
    }

    useEffect(() => {
        fetchInvoice()
    }, [])

    const onInvoiceCreated = () => {
        fetchInvoice()
        setSelectedInvoice(null)
    }

    const onInvoiceSelected = (invoice) => {
        setSelectedInvoice(invoice)
    }

    const [filteredInvoices, setFilteredInvoices] = useState([]);

    useEffect(() => {
        setFilteredInvoices(invoice)
    }, [invoice])


    return (
        <Box>

            {/* <h1>Expenditure Xero</h1> */}
            {/* <Button onClick={fetchInvoice}>Fetch Invoice</Button> */}
            {/* {apiReq.loading && <LinearProgress />} */}

            <Card sx={{
                mb: 1,
                boxShadow: "0 0 0 1px rgba(0, 10, 30, .1)"
            }}>
                <MuiDataGrid data={filteredInvoices} loading={apiReq.loading} columns={INVOICE_COLUMNS} noCard={true}
                    density="compact"
                    filters={<Box>
                        <GeneralDropdownFilter data={invoice} column="expense_type"
                            label="Expense Type" onFilter={(data) => {
                                setFilteredInvoices(data)
                            }}
                            sx={{
                                minWidth: 200, maxWidth: 400
                            }}
                        />
                    </Box>}
                    customHeader={<MetaHeader invoice={invoice} />}
                    customHeaderSX={{
                        height: 40
                    }}
                    searchPlaceholder={"Search by Invoice/Case Number, Contact or Amount"}
                />
            </Card>

            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 0.5, backgroundColor: "rgb(242, 242, 242)" }}>
                <Typography>Total:</Typography>
                <Typography>{invoice.reduce((a, b) => a + (b['AmountDue'] || 0), 0).toFixed(2)}</Typography>

            </Box> */}
            <Box>
                {/* <InvoiceCreationDialog romacCaseId={romacCaseId} onInvoiceCreated={onInvoiceCreated} setSelectedInvoice={setSelectedInvoice} selectedInvoice={selectedInvoice} /> */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                </Box>
            </Box>
        </Box>
    )
}