import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Divider from '@mui/material/Divider';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from "react-redux";
import { setCaseValue } from "../../../../../redux/case/case";
import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";

export default function OriginalEstimate() {
    const { data } = useSelector(state => state.patient_case)
    const dispatch = useDispatch()

    const addNewExpenditure = () => {
        dispatch(setCaseValue({
            name: 'original_estimate', value: [
                ...data.original_estimate, { source: '', medical: 0, other: 0, total: 0 }
            ]
        }))
    }
    const deleteExpenditureAtIndex = (index) => {
        if (index >= 0 && index < data.original_estimate.length) {
            // Use filter to remove the item at the specified index
            const updatedEstimate = data.original_estimate.filter((_, i) => i !== index);

            dispatch(setCaseValue({
                name: 'original_estimate',
                value: updatedEstimate
            }));
        } else {
            console.error("Invalid index provided for deletion");
        }
    }


    const onChange = (e) => {
        const { name, value } = e.target
        dispatch(setCaseValue({
            name: name, value: value
        }))

    }

    const onChangeText = (e) => {
        const { name, value, index } = e;

        // Create a new array with updated objects
        const oe = data.original_estimate.map((item, idx) => {
            if (idx !== index) { return item; }// Return original object if it's not the one we want to update
            return { ...item, [name]: value }// Only create a new object for the one we're updating
        });

        // Dispatch the updated array
        dispatch(setCaseValue({
            name: 'original_estimate',
            value: oe
        }));
    }



    if (!data) {
        return <Box></Box>
    }

    return (
        <Box>
            <Divider />
        <Box sx={{ mt: 1 }}>
            {/* <CustomInputLabel>Current Forecast</CustomInputLabel> */}
            <Box sx={{ display: 'flex', gap: '10px', mb: 1 }}>
                <InputLabel sx={{ mt: 0.4 }}>Original Estimate</InputLabel>
                <Button size="small" sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => addNewExpenditure("currentForecast")}>
                    Add
                </Button>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item md={7}>
                    <Grid container spacing={2} columns={10}>
                        <Grid item md={2}>
                            <CustomInputLabel>Source</CustomInputLabel>

                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Medical</CustomInputLabel>

                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Other</CustomInputLabel>
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Total</CustomInputLabel>
                        </Grid>

                    </Grid>
                    {data.original_estimate.map((item, index) => (
                        <Grid container spacing={2} columns={10} key={`original-estimate-${index}`} sx={{ mb: 0.3 }}>
                            <Grid item md={2}>
                                {/* <CustomInputLabel>Source</CustomInputLabel> */}
                                <FormControl fullWidth>
                                    <Select
                                        name="source"
                                        size="small"
                                        dataindex={index}
                                        onChange={(e) => onChangeText({
                                            name: 'source', value: e.target.value, index: index
                                        })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={item.source}
                                        fullWidth
                                    >
                                        <MenuItem value={0}>AUD</MenuItem>
                                        <MenuItem value={1}>NZD</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                <TextField size="small" name="medical" onChange={(e) => onChangeText({
                                    name: 'medical', value: e.target.value, index: index
                                })} dataindex={index} fullWidth value={item.medical} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                            </Grid>
                            <Grid item md={2}>
                                <TextField size="small" name="other" onChange={(e) => onChangeText({
                                    name: 'other', value: e.target.value, index: index
                                })} dataindex={index} fullWidth value={item.other} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                            </Grid>
                            <Grid item md={2}>
                                <TextField size="small" disabled fullWidth value={parseInt(item.medical) + parseInt(item.other)} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                            </Grid>
                            <Grid item md={2}>
                                {index > 0 &&
                                    <IconButton onClick={() => deleteExpenditureAtIndex(index)}>
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                }
                            </Grid>

                        </Grid>
                    ))}

                </Grid>
                <Grid item md={5}>
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <CustomInputLabel>Bed Days (Estimate)</CustomInputLabel>
                        </Grid>
                        <Grid item md={4}>
                            <CustomInputLabel>ICU</CustomInputLabel>
                            <TextField size="small" name="estimate_icu" onChange={onChange} value={data.estimate_icu} />
                        </Grid>
                        <Grid item md={4}>
                            <CustomInputLabel>Ward</CustomInputLabel>
                            <TextField size="small" name="estimate_ward" onChange={onChange} value={data.estimate_ward} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        </Box>

    )
}