import Box from '@mui/material/Box'
import PerPageSelect from "./PerPageDropdown";
import CustomPagination from "./CustomPagination";

export default function TableFooter(props) {
    const { totalRows, perPage, currentPage, totalPages, onChangePageSize, onChangePage } = props


    const end_page_number = currentPage !== totalPages-1 ? (currentPage + 1) * perPage : totalRows

    return (


        <Box sx={{ borderTop: '1px solid #ccced2', px: 1 }}>
            <Box sx={{ py: 2, justifyContent: 'space-between', display: 'flex', color: 'rgba(0, 10, 30, .65)' }}>
                <Box sx={{
                    display: 'flex', gap: 2,
                    fontSize: '0.85em'
                }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ paddingLeft: '6px', pt: 0.7, mr: 0.4 }}>Items per page</Box>
                        <PerPageSelect pageize={perPage} onChangePageSize={onChangePageSize} />
                    </Box>
                    <Box sx={{ mt: 0.7 }}>
                        Showing items {currentPage * perPage + 1}-{end_page_number} of {totalRows}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <CustomPagination
                    onChangePage={onChangePage}
                    totalPages={totalPages}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    />
                </Box>
            </Box>
        </Box>

    )
}