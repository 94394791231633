import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

const CTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '12px'
}));



export default function DataTable(props) {
    const { data } = props
    const colors = {
        'completed': 'rgba(168, 170, 174, 1)',
        'pending': 'rgba(255, 159, 67, 1)',
        'success': 'rgba(40, 199, 111, 1)',
    }
    const reports = [
        ["R-0007-01", "TBA", "Orthopaedic", "", "REJECTED", "", "", "$0", "$0", "$0", "", "", "$0", "$0", "$0", "$0", "$0", "$0", "$0", "$0", "$0", "$0"],
    ]
    return (
        <div style={{
            width: "100%",
            overflowX: "auto"
        }}>
            <Table sx={{ minWidth: 650, overflowX: 'scroll' }} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ minWidth: '58px' }}></TableCell>
                        <TableCell sx={{ minWidth: '50px' }}></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ minWidth: '100px' }}></TableCell>
                        <TableCell sx={{ minWidth: '120px' }}></TableCell>
                        <TableCell colspan={5}>Estimate</TableCell>
                        <TableCell colspan={5}>Forecast</TableCell>
                        <TableCell colspan={5}>Actual</TableCell>

                        <TableCell sx={{ minWidth: '80px' }} colspan={2}>Cashflow</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell sx={{ minWidth: '58px' }}>Case ID</TableCell>
                        <TableCell sx={{ minWidth: '50px' }}>Region</TableCell>
                        <TableCell>Condition</TableCell>
                        <TableCell sx={{ minWidth: '100px' }}>Hospital</TableCell>
                        <TableCell sx={{ minWidth: '120px' }}>Status</TableCell>
                        {
                            [1, 2].map((item, index) => {
                                return ["Ward Days", "ICU Days", "Med", "Other", "Total"].map((tc, tc_index) => (
                                    <TableCell key={`tc-${index}-${tc_index}`}>{tc}</TableCell>
                                ));
                            })
                        }

                        <TableCell>Med</TableCell>
                        <TableCell>Travel</TableCell>
                        <TableCell>Accom</TableCell>
                        <TableCell>Other</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell sx={{ minWidth: '80px' }}>Allocations</TableCell>
                        <TableCell sx={{ minWidth: '100px' }}>Balances</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, row_index) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {/* {row.map((mt, mt_index) => (
                                <CTableCell>
                                    {mt_index === 0 ?
                                        <Link to={`/patients/${row.id}`} style={{ textDecoration: 'none', fontWeight: 500 }}>

                                            {mt}
                                        </Link>
                                        :
                                        mt}
                                </CTableCell>
                            ))
                            } */}
                            {[
                                row.case_romac_id, row.region, row.classification, row.hospital, row.status, row.estimate_ward,
                                row.estimate_icu, row.estimate_medical, row.estimate_other, row.estimate_total,
                                row.forecast_ward, row.forecast_icu, row.forecast_medical, row.forecast_other, row.forecast_total,
                                row.actual_medical, row.actual_travel, row.actual_accommodation, row.actual_other, row.actual_total, 
                                row.cash_flow_allocations, row.cash_flow_balances
                            ].map((item, col_index) => (
                                <CTableCell key={`rc-${row_index}-${col_index}`}>{item}</CTableCell>
                            ))}
                            {/* <TableCell>{ row.case_romac_id }</TableCell>
                            <TableCell>{ row.region }</TableCell>
                            <TableCell>{ row.classification }</TableCell>
                            <TableCell>{ row.hospital }</TableCell>
                            <TableCell>{ row.status }</TableCell>
                            <TableCell>{ row.estimate_ward }</TableCell>
                            <TableCell>{ row.estimate_icu }</TableCell> */}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}