import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import instance from '../../../../auth/utils/useJwt';
import useSnackbar from '../../../../components/Dialogs/SnackBar';

import CircularProgress from '@mui/material/CircularProgress';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';

export default function Medical(props) {
    const { register, reset, handleSubmit,
    } = useForm({ });
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const [apiReq, setApiReq] = useState({ loading: false, error: null })
    const [loading, setLoading] = useState(true)

    const get_medical = () => {
        instance.get(`/medical-details?id=${props.id}`).then(res => {
            reset(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        // On First load fetch data
        get_medical()
    }, [])

    const onSubmit = (data) => {
        data.id = props.id
        setApiReq({ loading: true, error: null })
        instance.patch("/medical-details", data).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            showSnackbar("Data updated successfully", "success")
        }).catch(err => {
            setApiReq({ loading: false, error: null })
            showSnackbar("Failed to update medical data", "error")
        })
    }

    const onError = () => {

    }

    return (
        <Box>
            <SnackbarComponent />
            {loading ?
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                    <CircularProgress />
                </Box>
                :
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CustomInputLabel>Medical Diagnosis</CustomInputLabel>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                rows={4}
                                {...register('medical_prognosis')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CustomInputLabel>Treatment Prior to Referral</CustomInputLabel>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                rows={4}
                                {...register('prior_treatment')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CustomInputLabel>Treating Doctors</CustomInputLabel>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                rows={4}
                                {...register('treating_doctors')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button disabled={apiReq.loading} type='submit' variant='contained'>Save</Button>
                    </Box>
                </form>
            }
        </Box>
    );
}
