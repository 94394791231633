import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/utils/useJwt';

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {
    try {
        const response = await instance.get('/admin/roles');
        return response.data; // API response is an array of roles
    } catch (error) {
        throw error;
    }
});



export const Roles = createSlice({
    name: 'roles',
    initialState: {
        allData: [],
        data: [],
        editRow: null,
        loading: false,
        error: null,
        perPage: 10,
        currentPage: 1,
        totalPages: 0,
    },
    reducers: {
        addRole: (state, action) => {
            state.data.push(action.payload);
        },
        setRolesData: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        changePage: (state, action) => {
            state.currentPage = action.payload;
            const startIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = startIndex + state.perPage;
            state.data = state.allData.slice(startIndex, endIndex);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.allData = action.payload;
                state.data = action.payload;
                // state.currentPage = 1;
                // if (action.payload.length > state.perPage) {
                //     state.data = action.payload.slice(0, state.perPage);
                //     state.totalPages = Math.ceil(action.payload.length / state.perPage);
                // } else {
                //     state.data = action.payload;
                //     state.totalPages = 1;
                // }
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { addRole, changePage, setRolesData } = Roles.actions;

export default Roles.reducer;
