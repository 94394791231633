import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { formatDate } from './utils';

import { DATE_FORMAT } from '../../configs/basic';




export default function DatePicker(props) {
    const { name, value } = props

    const onChange = (e) => {
        var dt = {
            name: name, value: formatDate(e?.$d)
        }
        if (props.onChange){
            props.onChange(dt)
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                format={DATE_FORMAT} onChange={onChange} name={name}
                value={value ? dayjs(value, DATE_FORMAT) : null}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                defaultValue={value ? dayjs(value, DATE_FORMAT) : null} />
        </LocalizationProvider>
    )
}

