import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Regions from './Tabs/Regions';
import RotaryClubs from './Tabs/RotaryClubs';
import Hospitals from './Tabs/Hospitals';
import MedicalClassifications from './Tabs/MedicalClassifications';
import Clinicians from './Tabs/Clinicians';
import ClinicianTypes from './Tabs/ClinicianTypes';
import Districts from './Tabs/Districts';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function ListTabs({
    open, setOpen, onChangeTab
}) {
    const [value, setValue] = React.useState(0);

    const tabData = [
        { label: 'Regions', btnLabel: "Region", component: <Regions open={open} setOpen={setOpen} />, id: "regions" },
        { label: 'Districts', btnLabel: "District", component: <Districts open={open} setOpen={setOpen} />, id: "districts" },
        { label: 'Rotary Clubs', btnLabel: "Club", component: <RotaryClubs open={open} setOpen={setOpen} />, id: "rotary-clubs" },
        { label: 'Hospitals', btnLabel: "Hospital", component: <Hospitals open={open} setOpen={setOpen} />, id: "hospitals" },
        { label: 'Medical Classifications', btnLabel: "Classification", component: <MedicalClassifications open={open} setOpen={setOpen} />, id: "medical-classifications" },
        { label: 'Clinicians', btnLabel: "Clinician", component: <Clinicians open={open} setOpen={setOpen} />, id: "clinicians" },
        { label: 'Clinician Types', btnLabel: "Clinician Type", component: <ClinicianTypes open={open} setOpen={setOpen} />, id: "clinician-types" }
    ]

    const handleChange = (event, newValue) => {
        // set tab to url
        window.history.pushState({}, '', `?tab=${tabData[newValue].id}`);
        setValue(newValue);
    };
    const tab = new URLSearchParams(window.location.search).get("tab");
    

    // get tab from url

    React.useEffect(() => {
        if (tab) {
            const tabIndex = tabData.findIndex(item => item.id === tab)
            onChangeTab(tabData[tabIndex].btnLabel)
            if (tabIndex !== -1) {
                setValue(tabIndex)
            }
        }
    }, [tab])

    React.useEffect(() => {
        if (!tab)
            window.history.pushState({}, '', `?tab=${tabData[value].id}`);
    }, [value])
    

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Patient Details">
                    {
                        tabData.map((item, index) => (
                            <Tab key={`tab-label-${index}`} label={item.label} {...a11yProps(index)} sx={{ textTransform: 'capitalize' }} />
                        ))
                    }

                </Tabs>
            </Box>
            {
                tabData.map((item, index) => (
                    <TabPanel key={`tab-${index}`} value={value} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </Box>
    );
}
