import { Link } from "react-router-dom"
import ChangeUserCol from "./components/ChangeUserCol"
import UserActions from "./components/UserActions"
import UserEnable from "./components/UserEnable"

export const COLUMNS = [
    // mui data grid columns
    { field: 'email', headerName: 'Email', flex: 1},
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'created', headerName: 'Created', flex: 1 },
    { field: 'last_login', headerName: 'Last Login', flex: 1 },
    { field: 'roles', headerName: 'Roles', flex: 1 },
    { field: 'enabled', headerName: 'Enabled', flex: 1, renderCell: (params) => {
        return <UserEnable id={params.row.id} enabled={params.row.enabled} />
    }},
    { field: 'xero_access', headerName: 'Xero', flex: 1, renderCell: (params) => {
        return <ChangeUserCol id={params.row.id} field="xero_access" value={params.row.xero_access} />
    }},
    { field: 'xero_pam', headerName: 'Xero PAM', flex: 1, renderCell: (params) => {
        return <ChangeUserCol id={params.row.id} field="xero_pam" value={params.row.xero_pam} />
    }},
    { field: 'donations', headerName: 'Donations', flex: 1, renderCell: (params) => {
        return <ChangeUserCol id={params.row.id} field="donations" value={params.row.donations} />
    }},
    { field: 'actions', headerName: 'Actions', width: 100, renderCell: (params) => {
        return (
            <UserActions row={params.row} />
        )
    }}
]