import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Divider from '@mui/material/Divider';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from "react-redux";
import { setCaseValue } from "../../../../../redux/case/case";
import { generateYearsList } from "../../../../../utils/generators";
import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";

export default function ActualCosts() {
    const { data, loading } = useSelector(state => state.patient_case)
    const dispatch = useDispatch()

    const addNewExpenditure = () => {
        dispatch(setCaseValue({
            name: 'actual_costs', value: [
                ...data.actual_costs, { source: '', medical: 0, other: 0, accommodation: 0, travel: 0, total: 0 }
            ]
        }))
    }
    const deleteExpenditureAtIndex = (index) => {
        if (index >= 0 && index < data.actual_costs.length) {
            // Use filter to remove the item at the specified index
            const updatedEstimate = data.actual_costs.filter((_, i) => i !== index);

            dispatch(setCaseValue({
                name: 'actual_costs',
                value: updatedEstimate
            }));
        } else {
            console.error("Invalid index provided for deletion");
        }
    }

    const onChangeText = (e) => {
        const { name, value, index } = e;
        console.log(name, value, index)
        // Create a new array with updated objects
        const oe = data.actual_costs.map((item, idx) => {
            if (idx !== index) { return item; }// Return original object if it's not the one we want to update
            return { ...item, [name]: value }// Only create a new object for the one we're updating
        });

        // Dispatch the updated array
        dispatch(setCaseValue({
            name: 'actual_costs',
            value: oe
        }));
    }

    const sum_val = (col) => {
        return data?.actual_costs.reduce((acc, item) => parseInt(acc) + parseInt(item[col]), 0);
    }

    const InputS = (props) => {
        return <TextField size="small" disabled fullWidth value={sum_val(props.column_name)} InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <AttachMoneyIcon />
                </InputAdornment>
            ),
        }} />
    }

    if (!data) {
        return <Box></Box>
    }

    return (

        <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', gap: '10px', mb: 0.5 }}>
                <InputLabel sx={{ mt: 0.4 }}>Actual Costs</InputLabel>
                <Button size="small" sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => addNewExpenditure("actualCosts")}>
                    Add
                </Button>
            </Box>
            <Divider />
            <Grid container spacing={2} columns={16} sx={{ mt: 0.5 }}>
                <Grid item md={2}>
                    <CustomInputLabel>Date</CustomInputLabel>
                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Source</CustomInputLabel>
                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Medical</CustomInputLabel>
                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Travel</CustomInputLabel>
                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Accommodation</CustomInputLabel>
                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Other</CustomInputLabel>
                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Total</CustomInputLabel>
                </Grid>
            </Grid>
            {data.actual_costs.map((item, index) => (
                <Grid container spacing={2} columns={16} key={`actural-costs-${index}`} sx={{ mb: 0.3 }}>
                    <Grid item md={2}>
                        {/* <CustomInputLabel>Date</CustomInputLabel> */}
                        <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={item.date}
                                        onChange={(e) => onChangeText({
                                            name: 'date', value: e.target.value, index: index
                                        })}
                                        fullWidth
                                    >
                                        {
                                            generateYearsList().map((item, index) => (
                                                <MenuItem key={index} value={item}>{item}</MenuItem>
                                            ))

                                        }
                                    </Select>
                                </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        {/* <CustomInputLabel>Source</CustomInputLabel> */}
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={item.source}
                                onChange={(e) => onChangeText({
                                    name: 'source', value: e.target.value, index: index
                                })}
                                fullWidth
                            >
                                <MenuItem value={0}>AUD</MenuItem>
                                <MenuItem value={1}>NZD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <TextField size="small" fullWidth value={item.medical} onChange={(e) => onChangeText({
                                            name: 'medical', value: e.target.value, index: index
                                        })} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            ),
                        }} />
                    </Grid>
                    <Grid item md={2}>
                        <TextField size="small" fullWidth value={item.travel} onChange={(e) => onChangeText({
                                            name: 'travel', value: e.target.value, index: index
                                        })} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            ),
                        }} />
                    </Grid>
                    <Grid item md={2}>
                        <TextField size="small" fullWidth value={item.accommodation} onChange={(e) => onChangeText({
                                            name: 'accommodation', value: e.target.value, index: index
                                        })} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            ),
                        }} />
                    </Grid>
                    <Grid item md={2}>
                        {/* <CustomInputLabel>Other</CustomInputLabel> */}
                        <TextField size="small" fullWidth value={item.other} onChange={(e) => onChangeText({
                                            name: 'other', value: e.target.value, index: index
                                        })} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            ),
                        }} />
                    </Grid>
                    <Grid item md={2}>
                        {/* <CustomInputLabel>Total</CustomInputLabel> */}
                        <TextField size="small" disabled fullWidth value={parseInt(item.medical) + parseInt(item.travel) + parseInt(item.accommodation) + parseInt(item.other)} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            ),
                        }} />
                    </Grid>
                    <Grid item md={2}>
                    {index > 0 &&
                                    <IconButton onClick={() => deleteExpenditureAtIndex(index)}>
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                }
                    </Grid>
                </Grid>
            ))}
            <Grid container spacing={2} columns={16} sx={{ mt: 1 }}>
                <Grid item md={2}>

                </Grid>
                <Grid item md={2}>
                    <CustomInputLabel>Total (AUD Equivalent)</CustomInputLabel>

                </Grid>
                <Grid item md={2}>
                    {/* <CustomInputLabel>Medical</CustomInputLabel> */}
                    <InputS column_name="medical" />
                </Grid>
                <Grid item md={2}>
                    {/* <CustomInputLabel>Travel</CustomInputLabel> */}
                    <InputS column_name="travel" />
                </Grid>
                <Grid item md={2}>
                    {/* <CustomInputLabel>Accomodation</CustomInputLabel> */}
                    <InputS column_name="accommodation" />
                </Grid>
                <Grid item md={2}>
                    {/* <CustomInputLabel>Other</CustomInputLabel> */}
                    <InputS column_name="other" />
                </Grid>
                <Grid item md={2}>
                    {/* <CustomInputLabel>Total</CustomInputLabel> */}
                    <TextField disabled size="small" fullWidth value={sum_val("medical") + sum_val("travel") + sum_val("accommodation") + sum_val("other")} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </Grid>
        </Box>

    )
}