import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

export default function MUIAutocompleteMultiple(props) {
    const { register, control, options, errors, ...restProps } = props;
    const isError = errors && errors[restProps.name];
    
    
    return (
        <Controller
            name={restProps.name}
            control={control}
            defaultValue={[]} 
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    multiple
                    options={options || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                size="small"
                            />
                        ))
                    }
                    getOptionLabel={(option) => option.label}
                    value={value}
                    onChange={(_, newValue) => onChange(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} size="small" variant="outlined" fullWidth />
                    )}
                />
            )}
        />
    );
}