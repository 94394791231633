import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import { deleteAccessToken, deleteRefreshToken } from '../../../auth/utils';
const settings = [
    { name: 'Profile', slug: '/user/profile', icon: <AccountCircleOutlinedIcon sx={{ color: "#4B465C" }} /> },
    // { name: 'Settings', slug: '/user/profile', icon: <SettingsOutlinedIcon sx={{ color: "#4B465C" }} /> },
    // { name: 'Info', slug: '/user/profile', icon: <QuestionMarkOutlinedIcon sx={{ color: "#4B465C" }}/> }
    
];

export default function Profile() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate()
    const userData = useSelector(state => state.authentication).userData
    const user = useSelector(state => state.user)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigateAndClose = (slug) => {
        setAnchorEl(null);
        navigate(slug)
    };

    const logoutUser = () => {
        deleteAccessToken()
        deleteRefreshToken()
        navigateAndClose("/login")
    }

    return (
        <>
            <Tooltip title="Open settings">
                <IconButton id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src={ userData.picture } />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ display: 'flex', py: 1, px: 2, gap: '10px', minWidth: '170px' }}>
                <Avatar sx={{ width: '38px', height: '38px' }} src={ userData.picture } />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{ userData.name }</Typography>
                    {/* <Typography sx={{ fontSize: '11px' }}>Paediatrist</Typography> */}
                </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                {settings.map((setting) => (
                    <MenuItem sx={{ py: 1.2 }} key={setting.name} onClick={() => navigateAndClose(setting.slug)}>
                        { setting.icon }
                        <Typography sx={{ ml: 1 }} textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                ))}
                </Box>
                <Divider />
                <MenuItem sx={{ py: 1.2 }} onClick={() => logoutUser()}>
                        <LogoutOutlinedIcon sx={{ color: '#4B465C' }} />
                        <Typography sx={{ ml: 1 }} textAlign="center">Logout</Typography>
                    </MenuItem>
            </Menu>
        </>
    );
}