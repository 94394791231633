import React, { useEffect } from "react";
import AppLayout from "../../components/layout/AppLayout";
import { useSelector, useDispatch } from "react-redux";
import { fetchReferralPatients } from "../../redux/referral_patients";
import BreadCrumbs from "../../components/BreadCrumbs";
import MuiDataGrid from "../../components/tables/MUIDataGrid";
import { GRID_COLUMNS } from "./utilities";
import { Box } from "@mui/material";
export default function Referrals() {
    const patients = useSelector(state => state.referral_patients)
    const dispatch = useDispatch()
    const { data, loading } = patients
    
    useEffect(() => {
        if (data.length === 0) {
            dispatch(fetchReferralPatients())
        }
    }, [])

 
    return (
        <AppLayout px={0} apppadding={"0px"}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', py: 1.7, px: 3, backgroundColor: "#fff", mb: 2, borderRadius: 'none' }}>

                <BreadCrumbs data={[{ text: "Referrals" }]} />
                {/* {canWrite &&
                    <Button component={Link} to="/patients/add/provisional" size="small" variant="contained" color="primary" startIcon={<AddIcon />}>New Referral</Button>
                } */}
            </Box>

            <MuiDataGrid data={data} loading={loading}
                columns={GRID_COLUMNS}
                // searchFunction={filterDataBySearch}
                searchPlaceholder={"Enter a Referral ID or First and/or Last Name"}
                searchSX={{
                    "@media screen and (max-width: 1500px)": {
                        maxWidth: '300px'
                    },
                    "@media screen and (max-width: 1200px)": {
                        maxWidth: '250px'
                    }
                }}
                // noRowsOverlay={regionSelectedFilters === 0 && NoRegionNoRowsOverlay}
            />

        </AppLayout>
    )
}