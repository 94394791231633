import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { Checkbox, Stack } from '@mui/material';
import instance from '../../../auth/utils/useJwt';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchUsers, setUsersData } from '../../../redux/users';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputLabel from '../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../components/Dialogs/CustomDialog';


export default function AddUser() {
    const [open, setOpen] = React.useState(false);
    const editRow = useSelector(state => state.users).editRow
    const [userRoles, setUserRoles] = React.useState([])
    const [formData, setFormData] = React.useState({
        email: '', username: '', roles: null, enabled: false
    })

    const dispatch = useDispatch()


    React.useEffect(() => {
        if (editRow) {
            console.log(editRow)
            setOpen(true)
            setFormData({
                email: editRow.email, username: editRow.name, enabled: editRow.enabled, roles: editRow.roles
            })
        }
    }, [editRow])


    const onChangeField = (e) => {
        if (e.target.name === 'enabled') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked
            })
        } else if (e.target.name === 'roles') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }

    }


    const onAddUser = () => {
        if (editRow) {
            instance.patch("/admin/users", {
                email: formData.email, username: formData.username, enabled: formData.enabled, roles: formData.roles, id: formData.id,
                id: editRow.id
            }).then(res => {
                setOpen(false)
                dispatch(setUsersData({
                    key: 'editRow', value: null
                }))
                dispatch(fetchUsers())
            }).catch(err => {
                console.log(err)
            })
            return
        }
        instance.post("/admin/users", formData).then(res => {
            setOpen(false)
            dispatch(fetchUsers())
        }).catch(err => {
            console.log(err)
        })
    }

    const getUserRoles = () => {
        instance.get("/admin/users/roles").then(res => {
            var roles = []
            res.data.forEach(element => {
                roles.push({ label: element.name, value: element.id })
            });
            setUserRoles(roles)
        }).catch(err => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        if (userRoles.length === 0) {
            getUserRoles()
        }
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        dispatch(setUsersData({
            key: 'editRow', value: null
        }))
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size="small" onClick={handleClickOpen}>
                Add User
            </Button>
            <GeneralDialog open={open} handleClose={handleClose} title={editRow ? 'Update User' : 'Add New User'}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                        <Button onClick={onAddUser} variant="contained" color="primary" autoFocus>
                            {editRow ? 'Update' : 'Add'}
                        </Button>
                    </Stack>
                }>
                <Box>
                    <Box sx={{ py: 1 }}>
                        <CustomInputLabel>Email</CustomInputLabel>
                        <TextField size="small" fullWidth name='email' value={formData.email} onChange={onChangeField} />
                    </Box>
                    <Box sx={{ py: 1 }}>
                        <CustomInputLabel>Username</CustomInputLabel>
                        <TextField size="small" fullWidth name='username' value={formData.username} onChange={onChangeField} />
                    </Box>
                    <Box sx={{ py: 1 }}>
                        <CustomInputLabel>Roles</CustomInputLabel>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={userRoles}
                            value={formData.roles}
                            size="small"
                            onChange={(event, newValue) => onChangeField({
                                target: {
                                    name: "roles", value: newValue
                                }
                            })}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />

                    </Box>
                    <Stack direction={"row"} gap={1} sx={{ py: 1 }}>
                        <Checkbox name='enabled' checked={formData.enabled} onChange={onChangeField} />
                        <CustomInputLabel sx={{ mt: 1.5 }}>Enabled</CustomInputLabel>
                    </Stack>
                </Box>
            </GeneralDialog>
        </div>
    );
}
