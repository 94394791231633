import * as React from 'react';
import { IconButton, ListItemButton, Button, Stack, Typography } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import instance from '../../../../../auth/utils/useJwt';

import { useDispatch } from 'react-redux';
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';
import { useNavigate } from 'react-router-dom';
export default function DeleteUser({
    id
}) {
    const [deleteReq, setDeleteReq] = React.useState({
        open: false, deleting: false
    })

    const navigate = useNavigate()
    const onAction = () => {

        setDeleteReq({ ...deleteReq, deleting: true })
        instance.delete(`/admin/users?id=${id}`).then(res => {
            setDeleteReq({ open: false, deleting: true })
            navigate('/admin/users')
        }).catch(err => {
            setDeleteReq({ open: false, deleting: true })
        })
    }

    const handleClose = () => {
        setDeleteReq({ open: false, deleting: false })
    }


    return (
        <>
            <ListItemButton onClick={() => {
                setDeleteReq({ open: true, deleting: false })
            }}>


                Delete User

            </ListItemButton>

            <GeneralDialog open={deleteReq.open}
                title='Delete User' handleClose={handleClose}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                        <Button onClick={onAction} variant="contained" color="primary" autoFocus>
                            Delete
                        </Button>
                    </Stack>
                }>
                <Typography>
                    Before deleting this user, consider transferring their data. Once deleted, the user can't access their account and any untransferred data will be deleted after 20 days.
                </Typography>
                <Typography color={"error"}>
                    This action is irreversible and can't be undone. So be sure before proceeding with this action.
                </Typography>
            </GeneralDialog>

        </>

    );
}



