import { Stack, Typography } from "@mui/material"

export default function DataRows({title, data}){
    return (
        <div style={{
            marginBottom: 20
        }}>
            <Typography variant="h6" sx={{mb: 2}} color="primary">{title}</Typography>
            {data.map((item, index) => (
                <Stack key={index} direction="row" spacing={2} justifyContent={"space-between"}>
                    <Typography variant="body1" sx={{
                        fontWeight: 600, fontSize: '13px'
                    }}>{item.title}</Typography>
                    <Typography variant="body1" sx={{
                        fontSize: '13px'
                    }}>{item.value}</Typography>
                </Stack>
            ))}
        </div>
    )
}