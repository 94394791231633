import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function HeaderCloseButton({ handleClose, disabled }) {
    return (
        <IconButton size="small" onClick={handleClose} disabled={disabled}>
            <CloseIcon />
        </IconButton>
    )
}

export default function GeneralDialog({
    title, open, handleClose, disabled, children, actions, closeButton, dialogTitleSx={}, dialogContentSx={},
    closeOutsideClick=false, maxWidth="sm", onFormSubmit=null
}) {

    const FormComp = onFormSubmit ? "form" : "div";

    return (
        <Dialog open={open} onClose={closeOutsideClick && handleClose} maxWidth={maxWidth}>
        <FormComp onSubmit={onFormSubmit}>
            <DialogTitle sx={{
                borderBottom: '1px solid #ccc', mb: 2, display: "flex", justifyContent: "space-between",
                ...dialogTitleSx
            }}>
                <Box sx={{
                    fontWeight: 'bold'
                }}>
                    {title}
                </Box>
                <Box>
                    {closeButton ?
                        closeButton
                        :
                        <HeaderCloseButton handleClose={handleClose} disabled={disabled} />
                    }
                </Box>
            </DialogTitle>
            <DialogContent sx={{
                minWidth: '500px',
                ...dialogContentSx
            }}>
                {children}
            </DialogContent>
            <DialogActions sx={{
                borderTop: '1px solid #ccc',
                px: 3, py: 2,
                ...actions.props?.sx
            }}>
                {
                    actions
                }
            </DialogActions>
        </FormComp>
        </Dialog>
    
    );
}
