import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const CustomTypography = styled(Typography)({
    color: '#000',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    padding: '5px 4px',
    borderRadius: '4px',
    cursor: 'pointer', // Make text clickable
    "&:hover": {
        backgroundColor: '#f2f2f3',
    }
});

const CustomMenuItem = styled(MenuItem)({
    color: '#06f',
    borderLeft: '3px solid'
});

export default function CaseSelectView({ row }) {
    const { cases } = row;
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeCase, setActiveCase] = useState(cases[0]?.case_id);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeDropdown = (patient_case) => {
        setActiveCase(patient_case);
        handleClose();

    };

    // get_case_url
    const get_case_url = () => {
        // if case id is less than 10, pad with 0s
        const case_id = activeCase?.toString().padStart(2, '0');
        return `/patients/${row.romac_id}/case/${case_id}`;
    }

    const open = Boolean(anchorEl);
    
    if (cases?.length === 0) {
        return
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomTypography onClick={handleClick} sx={{
                backgroundColor: open ? '#f6f6f7' : 'white',
            }}>
                {activeCase?.toString().padStart(2, '0')}
                    <ArrowDropDownIcon />
            </CustomTypography>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 300, // Adjust height as needed
                            width: 200, // Adjust width as needed
                        },
                    }}
                >
                    {cases.map(case_item => (
                        <CustomMenuItem key={case_item.case_id} onClick={() => handleChangeDropdown(case_item.case_id)} sx={{
                            borderLeftColor: case_item.case_id === activeCase ? '#09f' : '#fff',
                            backgroundColor: case_item.case_id === activeCase ? '#f6f6f7' : 'white',
                            '&:hover': {
                                borderLeftColor: '#06f',
                            }
                        }}>
                            {case_item.case_id?.toString().padStart(2, '0')}
                        </CustomMenuItem>
                    ))}
                </Menu>
            </Popover>

            <IconButton size='small' component={Link} to={get_case_url(activeCase)} >
                <NavigateNextIcon sx={{ color: '#06f' }} />
            </IconButton>
        </Box>
    );
}
