import { Chip, Stack } from "@mui/material"
import RoleActions from "./components/RoleActions"

export const COLUMNS = [
    { field: 'name', headerName: 'Role Name', width: 180 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'type', headerName: 'Type', width: 70 },
    { field: 'permissions', headerName: 'Permissions', width: 150, renderCell: (params) => {
        return (
            <Stack direction="row" spacing={1}>
                { params.row.permissions.map((item, index) => (
                    <Chip sx={{ fontSize: '13px', textTransform:'capitalize' }} label={item.toLowerCase()} size="small" />
                ))}
            </Stack>
        )
    }},
    { field: 'region', headerName: 'Region', width: 100 },
    { field: 'district', headerName: 'District', width: 150 },
    { field: 'actions', headerName: 'Actions', width: 100, renderCell: (params) => {
        return (
            <RoleActions row={params.row} />
        )
    }}
]