import { Button, Card, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import instance from "../../../../auth/utils/useJwt";
import { useEffect, useState } from "react";
import useSnackbar from "../../../../components/Dialogs/SnackBar";

const TIMEZONES = [
    { label: "UTC-12:00 - Dateline Standard Time", value: "UTC-12:00 - Dateline Standard Time" },
    { label: "UTC-11:00 - UTC-11", value: "UTC-11:00 - UTC-11" },
    { label: "UTC-10:00 - Hawaii-Aleutian Standard Time", value: "UTC-10:00 - Hawaii-Aleutian Standard Time" },
    { label: "UTC-09:30 - Marquesas Islands Time", value: "UTC-09:30 - Marquesas Islands Time" },
    { label: "UTC-09:00 - Alaska Standard Time", value: "UTC-09:00 - Alaska Standard Time" },
    { label: "UTC-08:00 - Pacific Standard Time", value: "UTC-08:00 - Pacific Standard Time" },
    { label: "UTC-07:00 - Mountain Standard Time", value: "UTC-07:00 - Mountain Standard Time" },
    { label: "UTC-06:00 - Central Standard Time", value: "UTC-06:00 - Central Standard Time" },
    { label: "UTC-05:00 - Eastern Standard Time", value: "UTC-05:00 - Eastern Standard Time" },
    { label: "UTC-04:30 - Venezuelan Standard Time", value: "UTC-04:30 - Venezuelan Standard Time" },
    { label: "UTC-04:00 - Atlantic Standard Time", value: "UTC-04:00 - Atlantic Standard Time" },
    { label: "UTC-03:30 - Newfoundland Standard Time", value: "UTC-03:30 - Newfoundland Standard Time" },
    { label: "UTC-03:00 - Argentina Standard Time", value: "UTC-03:00 - Argentina Standard Time" },
    { label: "UTC-02:00 - South Georgia Time", value: "UTC-02:00 - South Georgia Time" },
    { label: "UTC-01:00 - Azores Standard Time", value: "UTC-01:00 - Azores Standard Time" },
    { label: "UTC±00:00 - Greenwich Mean Time", value: "UTC±00:00 - Greenwich Mean Time" },
    { label: "UTC+01:00 - Central European Time", value: "UTC+01:00 - Central European Time" },
    { label: "UTC+02:00 - Eastern European Time", value: "UTC+02:00 - Eastern European Time" },
    { label: "UTC+03:00 - Moscow Standard Time", value: "UTC+03:00 - Moscow Standard Time" },
    { label: "UTC+03:30 - Iran Standard Time", value: "UTC+03:30 - Iran Standard Time" },
    { label: "UTC+04:00 - Azerbaijan Standard Time", value: "UTC+04:00 - Azerbaijan Standard Time" },
    { label: "UTC+04:30 - Afghanistan Time", value: "UTC+04:30 - Afghanistan Time" },
    { label: "UTC+05:00 - Pakistan Standard Time", value: "UTC+05:00 - Pakistan Standard Time" },
    { label: "UTC+05:30 - Indian Standard Time", value: "UTC+05:30 - Indian Standard Time" },
    { label: "UTC+05:45 - Nepal Time", value: "UTC+05:45 - Nepal Time" },
    { label: "UTC+06:00 - Bangladesh Standard Time", value: "UTC+06:00 - Bangladesh Standard Time" },
    { label: "UTC+06:30 - Cocos Islands Time", value: "UTC+06:30 - Cocos Islands Time" },
    { label: "UTC+07:00 - Indochina Time", value: "UTC+07:00 - Indochina Time" },
    { label: "UTC+08:00 - China Standard Time", value: "UTC+08:00 - China Standard Time" },
    { label: "UTC+08:45 - Australian Central Western Standard Time", value: "UTC+08:45 - Australian Central Western Standard Time" },
    { label: "UTC+09:00 - Japan Standard Time", value: "UTC+09:00 - Japan Standard Time" },
    { label: "UTC+09:30 - Australian Central Standard Time", value: "UTC+09:30 - Australian Central Standard Time" },
    { label: "UTC+10:00 - Australian Eastern Standard Time", value: "UTC+10:00 - Australian Eastern Standard Time" },
    { label: "UTC+10:30 - Lord Howe Standard Time", value: "UTC+10:30 - Lord Howe Standard Time" },
    { label: "UTC+11:00 - Solomon Islands Time", value: "UTC+11:00 - Solomon Islands Time" },
    { label: "UTC+12:00 - Fiji Time", value: "UTC+12:00 - Fiji Time" },
    { label: "UTC+12:45 - Chatham Islands Time", value: "UTC+12:45 - Chatham Islands Time" },
    { label: "UTC+13:00 - Tonga Time", value: "UTC+13:00 - Tonga Time" },
    { label: "UTC+14:00 - Line Islands Time", value: "UTC+14:00 - Line Islands Time" }
]


export default function TimeZone() {
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const user = useSelector(state => state.user)
    const [apiReq, setApiReq] = useState({ loading: false, error: null })
    const [timeZoneData, setTimeZoneData] = useState(null)
    const update_user = () => {
        if (!timeZoneData) {
            showSnackbar('Please select a timezone', 'error')
            return
        }

        setApiReq({ loading: true, error: null })
        instance.patch("/user", {
            timezone: timeZoneData.value
        }).then(res => {
            console.log(res)
            showSnackbar('Timezone updated successfully', 'success')
            setApiReq({ loading: false, error: null })
        }).catch(err => {
            console.log(err)

            setApiReq({ loading: false, error: "Error updating user timezone" })
            showSnackbar('Timezone update error', 'error')
        })
    }

    useEffect(() => {
        //setTimeZoneData()
        if (user.timezone) {
            setTimeZoneData({ label: user.timezone, value: user.timezone })
        }
    }, [])

    console.log(timeZoneData)
    console.log(user)



    return (
        <Box sx={{ pt: 1 }}>
            <SnackbarComponent />
            <Box>
                <Typography>Time Zone</Typography>
                <Typography sx={{
                    fontSize: '13px',
                    py: 1.4,

                }}>
                    Please select timezone
                </Typography>

            </Box>
            <Box>

                <Autocomplete
                    disablePortal
                    options={TIMEZONES}
                    value={timeZoneData}
                    onChange={(event, newValue) => {
                        // newValue will contain the selected timezone
                        setTimeZoneData(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                />

                <Box sx={{ mt: 2 }}>
                    <Button onClick={update_user} variant="contained" disabled={apiReq.loading} color="primary">{
                        apiReq.loading ? "Updating..." : 'Update Timezone'
                    }</Button>
                </Box>
            </Box>

        </Box>
    )
}