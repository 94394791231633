import { useEffect, useState } from "react"
import DataRows from "./DataRows"

export default function PatientData({data}){
    const [dataValues, setDataValues] = useState([])

    const get_languages = (langs) => {
        var langs_str = ""
        langs.forEach(element => {
            langs_str += ` ${element.name}` + ","
        });

        return langs_str.slice(0, -1);
    }

    useEffect(() => {
        setDataValues([
            { title: 'First Name', value: data?.first_name },
            { title: "Last Name", value: data?.last_name },
            { title: "Gender", value: data.gender },
            { title: "Patients date of birth known?", value: data.dob_known },
            { title: "Date of Birth", value: data.dob },
            { title: "Patient have a birth certificate?", value: data.has_birth_certificate },
            { title: "Estimate date of birth", value: data.dob_text },
            { title: "Height (cm)", value: data.height },
            { title: "Weight (kg)", value: data.weight },
            { title: "Understands English", value: data.understands_english },
            { title: "Languages Spoken", value: get_languages(data.languages) },
            { title: "Address", value: data.address },
            
            { title: "Country of Origin", value: data.country_of_origin },
            { title: "Nationality", value: data.citizenship },
        
            { title: "Religion", value: data.religion },
        ])
    }, [data])


    return (
        <div>
            <DataRows data={dataValues} title="Patient" />
        </div>
    )
}