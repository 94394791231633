import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
const columns = [
    { field: 'case_romac_id', headerName: 'ID', width: 100 },
    { field: 'region', headerName: 'Region' },
    { field: 'classification', headerName: 'Condition' },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'hospital', headerName: 'Hospital' },
    { field: 'estimate_ward', headerName: 'Ward Days' },
    { field: 'estimate_icu', headerName: 'ICU Days' },
    { field: 'estimate_medical', headerName: 'Med' },
    { field: 'estimate_other', headerName: 'Other' },
    { field: 'estimate_total', headerName: 'Total' },
    { field: 'forecast_ward', headerName: 'Ward Days' },
    { field: 'forecast_icu', headerName: 'ICU Days' },
    { field: 'forecast_medical', headerName: 'Med' },
    { field: 'forecast_other', headerName: 'Other' },
    { field: 'forecast_total', headerName: 'Total' },

    
    { field: 'actual_medical', headerName: 'Med' },
    { field: 'actual_travel', headerName: 'Travel' },
    { field: 'actual_accommodation', headerName: 'Accom' },
    { field: 'actual_other', headerName: 'Other' },
    { field: 'actual_total', headerName: 'All' },

    
    { field: 'cash_flow_allocations', headerName: 'Allocations' },
    { field: 'cash_flow_balances', headerName: 'Balance' },
];

/*
row.actual_medical, row.actual_travel, row.actual_accommodation, row.actual_other, row.actual_total, 
                                row.cash_flow_allocations, row.cash_flow_balances*/
const columnGroupingModel = [


    {
        groupId: 'estimate',
        headerName: 'Estimate',
        description: '',
        children: [{ field: 'estimate_ward' }, { field: 'estimate_icu' }, { field: 'estimate_medical' }, { field: 'estimate_other' }, { field: 'estimate_total' }],
    },
    {
        groupId: 'forecast',
        headerName: 'Forecast',
        description: '',
        children: [{ field: 'forecast_ward' }, { field: 'forecast_icu' }, { field: 'forecast_medical' }, { field: 'forecast_other' }, { field: 'forecast_total' }],
    },
    {
        groupId: 'actual',
        headerName: 'Actual',
        description: '',
        children: [{ field: 'actual_medical' }, { field: 'actual_travel' }, { field: 'actual_accommodation' }, { field: 'actual_other' }, { field: 'actual_total' }],
    },
    {
        groupId: 'cash_flow',
        headerName: 'Cash Flow',
        description: '',
        children: [{ field: 'cash_flow_allocations' }, { field: 'cash_flow_balances' }],
    }
];

const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#eee', // Set table heading row background to #eee
    },
});

export default function DataGridTable(props) {
    const { loading, data } = props
    return (
        <div style={{ maxHeight: 600, width: '100%' }}>
            <StyledDataGrid
                rows={data}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
                columns={columns}
                loading={loading}
                width={'100%'}
                sx={{ width: '100%', maxHeight: '600px' }}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                    },
                }}
                pageSizeOptions={[15, 25]}
                slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress, }}
                density="compact"

            />
        </div>
    );
}