import { Button, Card } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';

import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import { useState, useEffect } from "react";
import instance from "../../../auth/utils/useJwt";
import Divider from '@mui/material/Divider';
import EditProfileData from "./EditProfileData";
import DetailsCard from "./DetailsCard";
import Settings from "./Settings";
import ProfileMenu from "./DropMenu";

export default function UserProfile() {
    const [editModalOpen, setEditModelOpen] = useState(false)
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const [userData, setUserData] = useState(null)
    const onEditClick = () => {
        setEditModelOpen(true)
    }

    const fetchUserData = () => {
        setApiReq({loading: true, error: null})
        instance.get("/user?type=detailed").then(res => {
            setUserData(res.data.data)
            console.log(res.data.data)
            setApiReq({loading: false, error: null})
        }).catch(err => {
            setApiReq({loading: false, error: "Error loading user data"})
        })
    }
    useEffect(() => {
        fetchUserData()
    }, [])

    const onUpdate = () => {
        setEditModelOpen(false)
        fetchUserData()
    }

    return (
        <AppLayout>
            <Box sx={{ my: 2 }}>
                <Typography variant="body" sx={{ pr: 1, lineHeight: 1 }}>Your ROMAC Account</Typography>
                
            </Box>
            <Card>
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                    borderBottom: '1px solid #b8bfe3'
                }}>
                    <Box sx={{ pt: 0.5, px: 1 }}>
                        <Typography variant="h6">Profile</Typography>
                    </Box>
                    <Box>
                        <ProfileMenu onEditClick={onEditClick} />
                    </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                    <DetailsCard apiReq={apiReq} userData={userData} />
                </Box>
            </Card>
            <Card sx={{ mt: 3 }}>
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                    borderBottom: '1px solid #b8bfe3'
                }}>
                    <Box sx={{ pt: 0.5, px: 1 }}>
                        <Typography variant="h6">Settings</Typography>
                    </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Settings />
                </Box>
            </Card>
            <EditProfileData userData={userData} onUpdate={onUpdate} open={editModalOpen} handleClose={() => setEditModelOpen(false)} />
        </AppLayout>
    )
}