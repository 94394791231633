import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import NewContactForm from './NewContactForm';

const NewContactDialog = (props) => {
  const { onNewContact } = props
  const [open, setOpen] = useState(false);
 
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box sx={{
        color: "#09f", fontSize: '12px',
        "&:hover": {
          color: "#03f",
          cursor: "pointer",
          textDecoration: "underline"
        }
      }} onClick={() => setOpen(true)}>New Contact</Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ borderBottom: '1px solid #eee' }}>Create New Contact</DialogTitle>
        <NewContactForm onNewContact={onNewContact} handleClose={handleClose} />
      </Dialog>
    </div>
  );
};

export default NewContactDialog;
