import React from "react";
import AppLayout from "../../components/layout/AppLayout";
import { Box, Typography, Grid, Card, CardHeader, CardContent } from "@mui/material";
import GmailIcon from '../../assets/images/icons/gmail.png'
import CalendarIcon from '../../assets/images/icons/calendar.png'
import GroupsIcon from '../../assets/images/icons/groups.png'
import MeetIcon from '../../assets/images/icons/meet.png'
import ChatIcon from '../../assets/images/icons/chat.png'

import ContactIcon from '../../assets/images/icons/contacts.png'

import GoogleSearchIcon from '../../assets/images/icons/google.png'
import GoogleDriveIcon from '../../assets/images/icons/google-drive.png'

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GoogleDocsIcon from '../../assets/images/icons/google-docs.png'
import GoogleSheetsIcon from '../../assets/images/icons/sheets.png'
import GoogleSlidesIcon from '../../assets/images/icons/google-slides.png'
import GoogleSitesIcon from '../../assets/images/icons/google-sites.png'
import GoogleFormsIcon from '../../assets/images/icons/google-forms.png'
import GoogleTasksIcon from '../../assets/images/icons/google-tasks.png'
import WhatToDo from '../../assets/images/icons/what-to-do-on-your-first-day.svg'

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import BContainer from "../../components/BContainer";
import BreadCrumbs from "../../components/BreadCrumbs";


const ButtonG = ({ title, url }) => {
    return (
        <Box
            onClick={() => window.open(url, '_blank', 'noreferrer')}
            sx={{
                cursor: 'pointer', backgroundColor: "#f2f2f2", color: "#0b57d0", display: 'inline-block',
                px: 3, py: 1, borderRadius: 1, fontSize: '17px', fontWeight: 500,
                boxShadow: '0 1px 2px 0 rgba(48, 48, 48, .30), 0 1px 3px 1px rgba(48, 48, 48, .15)'
            }}>
            {title}
        </Box>
    )
}

export default function Training() {

    const APPS = [
        {
            "name": "Gmail",
            "desc": "Custom business email",
            "icon": GmailIcon,
            "link": "https://support.google.com/a/users/answer/9259748?sjid=1193577859779652458-EU"
        },
        {
            "name": "Calendar",
            "desc": "Shared calendars",
            "icon": CalendarIcon,
            "link": "https://support.google.com/a/users/answer/9247501"
        },
        {
            "name": "Chat",
            "desc": "Messaging & spaces for teams",
            "icon": ChatIcon,
            "link": "https://support.google.com/a/users/answer/9247502"
        },
        {
            "name": "Meet",
            "desc": "Video & voice conferencing",
            "icon": MeetIcon,
            "link": "https://support.google.com/a/users/answer/9282720"
        },
        {
            "name": "Groups",
            "desc": "Team email & security lists",
            "icon": GroupsIcon,
            "link": "https://support.google.com/a/users/answer/9282667"
        },
        {
            "name": "Contacts",
            "desc": "Personal email lists",
            "icon": ContactIcon,
            "link": "https://support.google.com/a/users/answer/9282487"
        }
    ]

    const PROJECTS_TO_LIFE = [
        {
            "name": "Docs",
            "desc": "Word processing",
            "icon": GoogleDocsIcon,
            "link": "https://support.google.com/a/users/answer/9282664"
        },
        {
            "name": "Sheets",
            "desc": "Spreadsheets",
            "icon": GoogleSheetsIcon,
            "link": "https://support.google.com/a/users/answer/9282959"
        },
        {
            "name": "Slides",
            "desc": "Presentations",
            "icon": GoogleSlidesIcon,
            "link": "https://support.google.com/a/users/answer/9282488"
        },
        {
            "name": "Tasks",
            "desc": "Assignment tracking",
            "icon": GoogleTasksIcon,
            "link": "https://support.google.com/a/users/answer/9991367"
        },
        {
            "name": "Sites",
            "desc": "Website builder",
            "icon": GoogleSitesIcon,
            "link": "https://support.google.com/a/users/answer/9282722"
        },
        {
            "name": "Forms",
            "desc": "Surveys & quizzes",
            "icon": GoogleFormsIcon,
            "link": "https://support.google.com/a/users/answer/9991170"
        }
    ]
    // Smart search across Google Workspace
    const SS_GS = [
        {
            "name": "Drive",
            "desc": "Cloud storage",
            "icon": GoogleDriveIcon,
            "link": "https://support.google.com/a/users/answer/9282958"
        },
        {
            "name": "Google Search",
            "desc": "Smart search across Google Workspace",
            "icon": GoogleSearchIcon,
            "link": "https://support.google.com/cloudsearch/?sjid=1193577859779652458-EU#topic=6262835"
        }
        // {
        //     "name": "Mobile",
        //     "desc": "Google Workspace on mobile devices",
        //     "icon": VoiceIcon,
        //     "link": "https://support.google.com/a/users/answer/9282663"
        // }
    ]

    const SingleItem = ({ index, app, size }) => {
        return (
            <Grid key={index} item md={
                size ? size : 4
            }>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                    <img src={app.icon} style={{ width: 30 }} />
                    <Box sx={{ ml: 2 }}>
                        {/* <Typography variant="body1">{app.name}</Typography> */}
                        {/* On click, open new tab with url */}
                        <Typography variant="body1"><a href={app.link} target="_blank" rel="noreferrer" style={{
                            textDecoration: 'none', color: '#0b57d0', display: 'inline-flex', alignItems: 'center'
                        }}>
                            {app.name} <OpenInNewIcon sx={{ fontSize: '14px', verticalAlign: 'middle' }} />
                        </a></Typography>
                        <Typography variant="body2">{app.desc}</Typography>
                    </Box>
                </Box>
            </Grid>
        )
    }


    return (
        <AppLayout title="Training" px={0} apppadding={"0px"}>
        <BContainer>
            <BreadCrumbs data={[
                { text: "Training" }
            ]} />
        </BContainer>
            <Card sx={{ maxWidth: '800px', margin: '0 auto', mt: 2 }}>
                <CardHeader title="Training" sx={{ borderBottom: '1px solid #ddd' }} />
                <CardContent sx={{ px: 5 }}>

                    <Box>
                        


                        {/* <h3>Traininig</h3> */}

                        <Box sx={{ py: 3, borderBottom: '2px dotted #ccc' }}>
                            <Typography variant="h5">1. ROMAC Training</Typography>
                            <Typography variant="body">
                                Use these guides to speed quickly through the basics and get started with the app.
                            </Typography>
                            <Alert severity="info" sx={{ mt: 2 }}>
                            <AlertTitle>Under development</AlertTitle>
                            Training page is still under development
                        </Alert>
                        </Box>

                        <Box sx={{ py: 3, borderBottom: '2px dotted #ccc' }}>
                            <Typography variant="h5">2. Goggle Workspace: Get Started</Typography>
                            <Typography variant="body">
                                Use these guides to speed quickly through the basics and get started with the app.
                            </Typography>

                            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                <Box>
                                    <img src={WhatToDo} />
                                </Box>
                                <Box>
                                    
                            <Typography variant="h6" sx={{ fontWeight: 500 }}>Your first days with Google Workspace</Typography>
                                <Typography variant="body" sx={{ fontSize: '13px' }}>
                                Use these checklists to guide you through your first days and weeks with Google Workspace
                            </Typography>
                            <Box sx={{ py: 2, display: 'flex', gap: 2 }}>
                                <ButtonG title="What to do on day 1" url="https://support.google.com/a/users/answer/9296686" />
                                <ButtonG title="Week 1 & beyond" url="https://support.google.com/a/users/answer/9296612" />
                            </Box>
                                </Box>
                            </Box>
                            
                        </Box>

                        <Box sx={{ py: 3, borderBottom: '2px dotted #ccc' }}>
                            <Typography variant="h5">3. Learn about the apps</Typography>
                            <Typography variant="body">
                                Google Workspace apps help you work from anywhere on any device. Click below for tips and training about each app.
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Connect with teammates wherever they are
                            </Typography>

                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                {
                                    APPS.map((app, index) => {
                                        return <SingleItem key={index} index={index} app={app} />
                                    })
                                }
                            </Grid>
                        </Box>
                        <Box sx={{
                            py: 3,
                            borderBottom: '2px dotted #ccc'
                        }}>
                            <Typography variant="h6">Bring projects to life</Typography>


                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                {
                                    PROJECTS_TO_LIFE.map((app, index) => {
                                        return <SingleItem key={index} index={index} app={app} />
                                    })
                                }
                            </Grid>
                        </Box>
                        <Box sx={{
                            py: 3,
                            // borderBottom: '2px dotted #ccc'
                        }}>
                            <Typography variant="h6">Control and access files and data</Typography>


                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                {
                                    SS_GS.map((app, index) => {
                                        return <SingleItem key={index} index={index} app={app} size={6} />
                                    })
                                }
                            </Grid>
                        </Box>

                    </Box>
                </CardContent>
            </Card>
        </AppLayout>
    )
}