import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import instance from "../../../../auth/utils/useJwt";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom';

import { useState } from 'react';
import { useForm } from "react-hook-form";
import { DialogContentText, Stack } from '@mui/material';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';



export default function DeletePatientDialog(props) {

    const { open, setOpen } = props

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const navigate = useNavigate()
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    const { patient_id } = useParams()

    const onSubmit = () => {
        setApiReq({ loading: true, error: null })
        instance.delete(`/patients?id=${props.patient_id}`).then(res => {
            props.showSnackbar("Patient deleted successfully", "success")
            props.setOpen(false)
            setApiReq({ loading: false, error: null })
            navigate(`/patients`)
        }).catch(err => {
            console.log("Error")
            //props.setOpen(false)
            // if error code is 409, show error message
            if (err?.response?.status === 409) {
                props.showSnackbar(err.response.data, "error")
            } else {
                props.showSnackbar("Error deleting patient", "error")
            }

            setApiReq({ loading: false, error: "Error deleting patient" })
        })
    }

    const onError = (error) => {
        //alert("error")
    }

    const handleClose = () => {
        setOpen(false)
    }


    return (

        <GeneralDialog open={open} handleClose={handleClose} title="Delete Patient"
            disabled={apiReq.loading} onFormSubmit={handleSubmit(onSubmit, onError)}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                    <Button type="submit" disabled={apiReq.loading} variant="contained">
                        {
                            apiReq.loading ? `DELETING` : `DELETE`
                        }
                    </Button>
                </Stack>
            }>
            <DialogContentText sx={{ mb: 1 }}>Are you sure you want to delete this patient. Please enter Patient id to delete this patient. This action cannot be undone.
                "<b>{patient_id}</b>"
            </DialogContentText>
            <TextField size="small" {...register('patient_id', {
                required: true, validate: (value) => {
                    const expectedValue = patient_id;

                    // Check if the value matches the expected value
                    return value === expectedValue || 'Invalid patient patient ID';
                }
            })} name="patient_id"
                error={Boolean(errors.patient_id)}
                placeholder='Patient ID'
                fullWidth
            />



        </GeneralDialog>
    )
}