import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomTypography = styled(Typography)({
    color: '#06f',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    marginLeft: '6px',
    padding: '5px 4px',
    paddingLeft: '8px',
    borderRadius: '4px',
    cursor: 'pointer', // Make text clickable
    "&:hover": {
        backgroundColor: '#f2f2f3',
    }
});

const CustomMenuItem = styled(MenuItem)({
    color: '#06f',
    borderLeft: '3px solid'
});

export default function PerPageSelect({ onChangePage, totalPages, currentPage }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageChange = (page) => {
        onChangePage(page);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
        <div>
            <CustomTypography onClick={handleClick} sx={{
                backgroundColor: open ? '#f6f6f7' : 'white',
            }}>
                Page {currentPage + 1} of {totalPages}
                    <ArrowDropDownIcon />
            </CustomTypography>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 300, // Adjust height as needed
                            width: 200, // Adjust width as needed
                        },
                    }}
                >
                    {pages.map(page => (
                        <CustomMenuItem key={page} onClick={() => handlePageChange(page - 1)} sx={{
                            borderLeftColor: page === currentPage + 1 ? '#09f' : '#fff',
                            backgroundColor: page === currentPage + 1 ? '#f6f6f7' : 'white',
                            '&:hover': {
                                borderLeftColor: '#06f',
                            }
                        }}>
                            {page}
                        </CustomMenuItem>
                    ))}
                </Menu>
            </Popover>
        </div>
    );
}
