import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'


export const User = createSlice({
  name: 'user',
  initialState: {
    fullname: '',
    email : '',
    username: '',
    id : '',
    role: {
      permissions: []
    },
    profile_pic: '',
    first_login_date: '',
  },
  reducers: {
    setUser: (state, action) => {
      
      state.fullname = action.payload.full_name
      state.id = action.payload.id
      state.email = action.payload.email
      state.role = action.payload.role

      state.profile_pic = action.payload.profile_pic
      state.first_login_date = action.payload.first_login_date
      state.timezone = action.payload.timezone
      state.xero_access = action.payload.xero_access
      state.xero_pam = action.payload.xero_pam
      state.donations = action.payload.donations
    },
    setUserData: (state, action) => {
      state[action.payload.key] = action.payload.value
    }
  }
}) 


//state.role.permissions is an array of string ['VIEW', 'EDIT']

// Write a function to check if the user has permission to view a page
const selectPermissions = state => state.user.role?.permissions;
const selectRole = state => state.user.role;
const xeroAccess = state => state.user.xero_access;

// Function to check if the user has write permissions
export const hasWritePermission = createSelector(
    [selectPermissions],
    (permissions) => permissions?.includes('EDIT')
);

export const hasDeletePermission = createSelector(
    [selectRole],
    (role) => role.type === "A"
);

export const hasXeroAccess = createSelector(
    [xeroAccess],
    (xero_access) => xero_access
);

export const { setUser,setUserData} = User.actions

export default User.reducer