import React from 'react';
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

export default function UserCard() {
    const user = useSelector(state => state.user)
    const userData = useSelector(state => state.authentication).userData

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} sx={{ justifyContent: 'center', display: 'flex' }}>
                        <Avatar
                            src={userData.picture} // Replace with the path to your image or leave it if you want to set it externally
                            alt={user.fullname}
                            sx={{ width: 100, height: 100 }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h6" component="div" sx={{ pt: 1 }}>
                            Welcome, <b>{user.fullname}</b>
                        </Typography>
                        <Typography variant="body2" color="primary" component="div" sx={{ mt: 1.6 }}>
                            {user.email}
                        </Typography>
                        <Typography variant="body2" component="div" sx={{ mt: 1 }}>
                            <b style={{ color: "#777", fontWeight: 700 }}>Role / Region:</b> {user.role?.name ? user.role?.name : "N/A"} / {user.region ? user.region : "N/A"}
                        </Typography>
                        <Typography variant="body2" component="div">
                            <b style={{ color: "#777", fontWeight: 700 }}>Last Login:</b> today
                        </Typography>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}