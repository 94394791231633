import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import instance from '../../../../../auth/utils/useJwt';

import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';
import { Stack } from '@mui/material';
function DeleteDialog({ id, api_url, heading, onDelete }) {
    const [open, setOpen] = useState(false);

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const handleClose = () => {
        // Also set loading to false and error to null
        setApiReq({ loading: false, error: null })
        setOpen(false);
    };

    useEffect(() => {
        if (id) {
            setOpen(true)
        }
    }, [id])

    const handleConfirm = () => {
        setApiReq({ loading: true, error: null })
        instance.delete(`${api_url}?id=${id}`).then(res => {
            setApiReq({ loading: false, error: null })
            setOpen(false)
            onDelete()
        }).catch(err => {
            setApiReq({ loading: false, error: err.response.data ? err?.response?.data : "Error deleting" })
        })

    };

    return (
        <GeneralDialog open={open} handleClose={handleClose} title={heading}
            disabled={apiReq.loading}
            actions={
                <Stack direction="row" spacing={2}>
                    <Button disabled={apiReq.loading} onClick={handleClose} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button disabled={apiReq.loading} onClick={handleConfirm} variant="contained" autoFocus color="error">
                        Confirm
                    </Button>
                </Stack>
            }>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item?
            </DialogContentText>
            {/* If any error, show in red (small font size) */}
            {apiReq.error && <DialogContentText id="alert-dialog-description" sx={{ mt: 2, color: 'red', fontSize: '12px' }}>
                {apiReq.error}
            </DialogContentText>}
        </GeneralDialog>
    );
}

export default DeleteDialog;
