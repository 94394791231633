import * as React from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';
import './styles.css'
import MuiDataGrid from '../../../../../components/tables/MUIDataGrid';
import { Box } from '@mui/material';


export default function DataGridTableLists(props) {
    const { loading, data, cols, additionalComp } = props;
    // ... rest of your component
    const columns = [
        // { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        ...cols,
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 100,
            //disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <IconButton aria-label='Edit' onClick={() => params.row.id && props.handleEdit(params.row)}>
                            <ModeEditOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                        </IconButton>

                        <IconButton aria-label='Delete' onClick={() => params.row.id && props.handleDelete(params.row.id)}>
                            <DeleteForeverOutlinedIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </div>
                );
            }
        }
    ];
    return (
        <div style={{ width: '100%' }}>
            <MuiDataGrid
                noCard={true}
                data={data}
                columns={columns}
                loading={loading}
            />
        </div>
    );
}

