import React from 'react'
import Grid from '@mui/material/Grid'
import AuthBG from '../../assets/images/bg/bg2.jpg'
import { Box } from '@mui/material'

export default function AuthLayout({ children }) {
    return (
        <Box sx={{
            backgroundImage: `url('${AuthBG}')`,
            backgroundSize: 'cover',       // Cover the entire container
            backgroundRepeat: 'no-repeat', // Do not repeat the background
            backgroundPosition: 'bottom',
            height: '100vh',
            width: '100%',
        }}>
            <Grid container spacing={0}>
                <Grid item md={7} style={{ height: '100vh' }}>

                </Grid>
                <Grid item md={5}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', px: 5 }}>
                        <Box sx={{ backgroundColor: "#fff", maxHeight: '90vh', minHeight: '70vh' }}>
                            {children}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}